<template>
  <div class="generate-data-import-button">
    <button class="button" @click="downloadXls()">
      {{ i18n.generate_import_file }}
    </button>
    <div class="generated-columns-preview">
      <h2>{{ i18n.generated_columns }}</h2>
      <table v-if="schema" ref="export-table-preview" class="export-table-preview">
        <tr>
          <th
            v-for="p in schema.fields.filter(f => isFieldAllowedAtImport(f, upsertKey))"
            :key="p.model"
            data-b-l-c="#000000"
            data-b-r-c="#000000"
            data-b-t-c="#000000"
            data-f-bold="true"
            data-b-l-s="thin"
            data-b-b-s="medium"
            data-b-r-s="thin"
          >
            {{ p.label }}
          </th>
        </tr>
        <tr v-for="n in 10" :key="n" class="hidden">
          <td
            v-for="p in schema.fields.filter(f => isFieldAllowedAtImport(f, upsertKey))"
            :key="p.model"
            :data-t="typeFor(p)"
            data-b-r-s="thin"
            data-b-l-s="thin"
            data-b-l-c="#000000"
            data-b-r-c="#000000"
          />
        </tr>
      </table>
      <b-message v-else type="is-danger" has-icon>
        {{ i18n.no_schema_found }}
      </b-message>
    </div>
  </div>
</template>
<script>
import { ToastProgrammatic as Toast } from 'buefy';
import i18n from 'i18n/components/modals/GenerateDataImportFileButton.json';

export default {
  props: {
    upsertKey: {
      type: String,
      default: undefined,
    },
    schema: {
      type: [Object],
      default: undefined,
    },
    panel: {
      type: Object,
      required: true,
    },
    isFieldAllowedAtImport: {
      type: Function,
      default: undefined,
    },
  },
  data() {
    return {
      i18n,
      exportFunctions: {
        count: (e) => {
          if (e) {
            return e.length;
          }
        },
      },
      active: this.value,
    };
  },
  methods: {
    typeFor(property) {
      if (property.type === 'checkbox') {
        return 'b';
      }
      return 's';
    },
    async downloadXls() {
      if (this.$refs['export-table-preview']) {
        await this.$loadScript('https://cdnjs.cloudflare.com/ajax/libs/xlsx/0.15.6/xlsx.full.min.js');
        //const XLSX = await import(/* webpackChunkName: "xlsx" */ 'xlsx');

        const wb = XLSX.utils.book_new();
        wb.SheetNames.push('Data');
        const ws2 = XLSX.utils.table_to_sheet(this.$refs['export-table-preview']);
        wb.Sheets.Data = ws2;
        XLSX.writeFile(wb, `${this.panel.title}-import-template.xlsx`);
      } else {
        Toast.open(this.i18n.can_t_generate_import_because_no_schema_found);
      }
    },
  },
};
</script>
<style scoped>
.hidden {
  display: none;
}
.generate-data-import-button {
  margin-left: 20px;
}
.generated-columns-preview {
  margin-top: 20px;
}
tr {
  display: grid;
  margin-top: 20px;
  grid-template-columns: 20% 20% 20% 20% 20%;
}
h2 {
  margin-top: 12px;
  font-weight: bold;
}
tr > th {
  display: block;
  font-weight: normal;
  font-size: 80%;
  border: 1px solid #dedede;
  border-radius: 8px;
  padding: 4px;
  margin: 4px;
}
</style>
