<template>
  <span>
    {{expanded ? value : value.substr(0, 200) + '...' }}
    <a @click="expanded = !expanded">
      {{ expanded ? "See less" : "See more" }}
    </a>
  </span>
</template>
<script>
export default {
  name: 'TruncatedText',
  props: {
    value: {
      type: String,
      required: true,
    },
  },
  data() {
    return { expanded: false };
  },
};
</script>
<style scoped>
</style>
