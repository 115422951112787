<template>
  <div>
    <a class="trigger" @click="opened = !opened">
      <i class="mdi mdi-stack-exchange" />
    </a>
    <portal to="layout-right">
      <div class="card updates-card" v-if="opened">
        <div class="menu-header">
          Mises à jour
          <a @click="opened = false">
            <i class="mdi mdi-close" />
          </a>
        </div>
        <div v-if="notifications && notifications.length">
          <div class="notification card" v-for="n in notifications">
            <img :src="`https://avatars.dicebear.com/api/initials/${n.username}.svg`" :alt="n.username"/>
            <div class="notification-content">
              {{n.user}} a {{n.type === 'create' ? 'créé' : 'mis à jour'}} un document <span class="tag"># {{n.document ? n.document.oi_id : ''}}</span>
              <div class="date">
                {{new Date(n.date).toLocaleString()}}
              </div>
            </div>
          </div>
        </div>
        <div v-else class="placeholder">
          <i class="mdi mdi-update mdi-48px"/>
          <div>Pas de modification récente<br/> sur cette vue</div>
        </div>
      </div>
    </portal>
  </div>
</template>
<script>
import socket from '@/core/socket';

export default {
  props: {
    lockName: {
      type: String,
      required: true,
    },
  },
  data () {
    return {
      opened: false,
      notifications: [],
      notifyListener: (notification) => {
        this.notifications.push(notification);
      }
    };
  },
  mounted () {
    socket.io.on('notify', this.notifyListener);
  },
  beforeDestroy() {
    socket.io.off('notify', this.notifyListener);
  },
}
</script>
<style scoped>
.trigger {
  padding: 8px;
  display: inline-block;
}
</style>
<style>
.updates-card .card {
  margin-bottom: 0;
  position: relative;
}
.updates-card .card .date {
  opacity: 0.5;
  font-size: 80%;
}
.updates-card .notification {
  display: flex;
  gap: 10px;
}
.updates-card .notification > img {
  border-radius: 100%;
  width: 30px;
  height: 30px;
}
.updates-card {
  height: 100vh;
  width: 350px;
  background: white;
  z-index: 1;
}
.updates-card .placeholder {
  opacity: 0.5;
  text-align: center;
  margin: auto;
  padding-top: 300px;
  font-size: 140%;
}
.updates-card .menu-header {
  background: whitesmoke;
  box-shadow: 0 2px 3px rgb(10 10 10 / 10%), 0 0 0 1px rgb(10 10 10 / 10%);
  height: 40px;
  padding: 8px 20px;
  font-weight: bold;
}
.updates-card .menu-header a {
  float: right;
}
</style>
