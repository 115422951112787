<template>
  <div class="import-modal-welcome">
    <b-message>
      <h1>{{ i18n.welcome }}</h1>
      {{ i18n.descriptive_text }}
    </b-message>
  </div>
</template>
<script>
import i18n from 'i18n/components/modals/ImportModal/ImportModalWelcome.json';

export default {
  data() {
    return { i18n };
  },
};
</script>
<style scoped>
h1 {
  font-weight: bold;
}
.import-modal-welcome {
  padding: 20px;
}
</style>
