<template>
  <span>
    <span v-if="saveViewTitle" class="panel-title">
      {{savingPanelTitle}}
    </span>
    <span v-if="panel" v-show="!savingPanelTitle" ref="panelTitle" class="panel-title" contenteditable @blur="saveViewTitle" @keydown.enter.prevent="saveViewTitle">{{ panel.title || '&lt;Vue sans titre&gt;' }}</span>
  </span>
</template>
<script>
export default {
  props: {
    panel: {
      type: Object,
      default: undefined,
    }
  },
  data () {
    return {
      savingPanelTitle: undefined,
    };
  },
  methods: {
    async saveViewTitle () {
      this.savingPanelTitle = this.$refs.panelTitle.innerHTML.trim().replace(/(\r\n|\n|\r)/gm, "");
      if (this.savingPanelTitle !== this.panel.title) {
        const res = await this.$store.dispatch('abstractElements/saveObject', {
          collection: 'panels',
          object: { ...this.panel, title: this.savingPanelTitle },
        });
      }
      this.savingPanelTitle = undefined;
    }
  }
};
</script>
<style scoped>
.panel-title {
  cursor: pointer;
  padding-right: 4px;
}
.panel-title:hover {
  text-decoration: underline;
}
</style>
