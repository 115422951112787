<template>
  <span>
    <b-tooltip v-if="displayedObject && typeof displayedObject === 'object'" :label="JSON.stringify(displayedObject)" position="is-bottom">
      <span class="tag is-primary"><i class="mdi mdi-file" /> Object</span>
    </b-tooltip>
    <span v-else-if="displayedObject !== undefined && displayedObject !== ''" class="tag is-danger">
      <i class="mdi mdi-close" /> {{ displayedObject }}
    </span>
  </span>
</template>
<script type="text/javascript">

export default {
  props: {
    object: {
      type: [Object, String],
      default: undefined,
    },
    field: {
      type: Object,
      default: undefined,
    },
  },
  computed: {
    displayedObject() {
      if (typeof this.object === 'object') {
        const r = { ...this.object };
        delete r._id;
        return r;
      }
      return this.object;
    },
  },

};
</script>
