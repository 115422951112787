<template>
  <Modal :class="`edit-modal import-modal ${previewData && !importDone ? 'importing' : ''} `" :active="active" :width="1400" @close="$emit('input', false)">
    <template slot="header">
      <span class="modal-title">
        <button v-if="previewData" class="button is-small" @click="resetModal">
          <i class="mdi mdi-arrow-left" />
        </button>
        {{ i18n.import }}
        <button style="float: right;" class="button is-small" @click="$emit('input', false)">
          <i class="mdi mdi-close" />
        </button>
      </span>
      <b-message
        v-if="previewData && !importDone"
        :type="`${selectedDataValid && selectedRows.length ? 'is-success' : 'is-danger'}`"
        class="preview-container import-status-message"
        has-icon
      >
        <span v-if="selectedRows.length === 0">
          {{ i18n.no_data_imported }}
        </span>
        <span v-else-if="selectedDataValid">
          {{ i18n.valid_data }}
        </span>
        <span v-else>
          {{ i18n.invalid_data }}
        </span>
        <button
          :disabled="!selectedDataValid || selectedRows.length === 0"
          :class="`button import-data-button ${selectedDataValid && selectedRows.length ? 'is-success' : 'is-danger'}`"
          @click="importData"
        >
          <i class="mdi mdi-send" />
          Importer les données ({{ selectedRows.length }})
        </button>
        <b-dropdown style="float: right; margin-top: -6px; margin-right: 6px;" append-to-body>
            <a :class="`button ${selectedDataValid && selectedRows.length ? 'is-success': 'is-danger'}`" slot="trigger" style="color: white">
              <i class="mdi mdi-dots-vertical"/>
            </a>
            <b-dropdown-item @click="selectOnlyValidRows">
              Sélectionner seulement les lignes valides
            </b-dropdown-item>
        </b-dropdown>
      </b-message>
    </template>
    <b-message
      v-if="criticalError"
      type="is-danger"
      style="margin-top: 20px; margin-left: 40px; margin-right: 40px; margin-bottom: -20px; "
      has-icon
    >
      <span v-if="criticalError.location && criticalError.location === 'upsertKeysCheck'">
        {{i18n.error_upsertKeysCheck}}
      </span>
      <span v-else-if="criticalError.location && criticalError.location === 'fileRead'">
        {{i18n.error_fileRead}}
      </span>
      <span v-else>
        <span v-if="criticalError.message">
          <h1>{{ i18n[criticalError.message] || criticalError.message }}</h1>
        </span>
        <span v-else>
          {{ criticalError }}
        </span>
      </span>
    </b-message>

    <div v-if="!importDone" style="position: relative;">
      <b-progress v-if="importProgressTotal !== 0" :value="importProgressCount" :max="importProgressTotal" />
      <div v-if="importProgressTotal !== 0" style="margin: auto; text-align: center;">
        <h2 class="title is-2">
          {{importProgressMessage}}
        </h2>
        <b-loading :active="true" :is-full-page="false" :can-cancel="false" style="padding: 200px;" />
      </div>
      <div v-if="previewData && importProgressCount === 0" class="preview-container">
        <b-table
          :data="previewData"
          :checked-rows.sync="selectedRows"
          :row-class="(row, index) => rowClass(row, index)"
          :is-row-checkable="isRowCheckable"
          class="preview-table"
          striped
          hoverable
          sticky-header
          checkable
        >
          <b-table-column v-slot="props" cell-class="import-actions-cell" field="actions" label="actions" sticky>
            <template slot="header">
              Actions
            </template>
            <span>
              <button v-if="editedRow === undefined" class="button edit-document-button" @click="editedRow = props.row">
                <i class="mdi mdi-pencil" />
              </button>
              <button v-if="editedRow === undefined" class="button edit-document-button" @click="removeRow(props.row)">
                <i class="mdi mdi-delete" />
              </button>
              <button v-if="editedRow === props.row" class="button edit-document-button is-success" @click="editRow(props.row)">
                <i class="mdi mdi-check" />
              </button>
              <button v-if="editedRow === props.row" class="button edit-document-button is-danger" @click="editedRow = undefined">
                <i class="mdi mdi-close" />
              </button>
              <span v-else-if="editedRow !== undefined" style="width: 38px" />
              <ImportTypeBadge
                v-model="updateStatuses[props.index]"
                :row="props.row"
                :imported-fields="importedFields"
                :overwritten-fields="overwritenKeysFields"
                :panel="panel"
                :schema="schema"
                :previously-inserted-rows="previouslyInsertedRows"
              />
              <b-dropdown class="import-errors" aria-role="list" append-to-body>
                <button slot="trigger" :class="`button is-small ${props.row.__importErrors && props.row.__importErrors.length ? 'is-danger' : 'is-success'}`">
                  {{ props.row.__importErrors.length }} &nbsp;<i class="mdi mdi-alert-circle-outline" />
                </button>
                <b-dropdown-item v-for="e in props.row.__importErrors" :key="e">
                  {{ e }}
                </b-dropdown-item>
                <b-dropdown-item v-if="!props.row.__importErrors || props.row.__importErrors.length === 0">
                  {{ i18n.no_import_errors }}
                </b-dropdown-item>
              </b-dropdown>
            </span>
          </b-table-column>
          <b-table-column
            v-for="f in importedFields"
            v-slot="props"
            :key="f.model"
            :field="f.model"
            :label="f.label"
            sortable
          >
            <template slot="header">
              <i v-if="upsertKey && upsertKey === f.model" class="mdi mdi-key" />
              <span class="row-header-title">
                {{ f.label }}
              </span>
              <span v-if="f.required" class="required">*</span>
            </template>
            <span v-if="editedRow === props.row && editedRowData">
              <Minifield v-model="editedRowData[f.label]" :field="f" size="is-small" @keyUpEnter="editRow(props)" />
            </span>
            <span v-else-if="props.row && f.type === 'textArea' && typeof props.row[f.model] === 'string'" class="row-value">
              {{ props.row[f.model].substring(0, 40) }}
              <span v-if="props.row[f.model].length > 40">...</span>
            </span>
            <span v-else-if="props.row && typeof props.row[f.model] === 'boolean'">
              <span :class="props.row[f.model] ? 'tag is-success is-small' : 'tag is-danger is-small'">
                <i :class="`mdi mdi-${props.row[f.model] ? 'check' : 'close'}`" />
              </span>
            </span>
            <span v-else-if="props.row && f.relation && !f.onlyFieldAsString">
              <InlineObjectRenderer :object="props.row[f.model]" :field="f" />
            </span>
            <span v-else-if="props.row" class="row-value">
              {{ prettyPrintValue(props.row[f.model]) }}
            </span>
          </b-table-column>
          <b-table-column v-for="f in overwritenKeysFields" v-slot="props" :key="f.model" :field="f.model" :label="f.label">
            <template slot="header">
              <i class="mdi mdi-lock" />
              {{ f.label }}
            </template>
            <span class="row-value">
              {{ prettyPrintValue(props.row[f.model]) }}
            </span>
          </b-table-column>
        </b-table>
        <label class="checkbox">
          <input v-model="showUnchanged" type="checkbox" style="margin-left: 20px; padding-top: 20px">
          {{ i18n.show_unchanged_records }} (<span class="unchanged-count">{{ unchangedValuesCount }}</span>)
        </label>
      </div>
      <div v-if="!previewData" style="padding: 20px;">
        <b-loading v-if="readingFile" :active="true" :is-full-page="false" :can-cancel="false" style="padding: 200px;" />
        <div v-else>
          <ImportModalWelcome />
          <GenerateDataImportFileButton
            :upsert-key="upsertKey"
            :is-field-allowed-at-import="isFieldAllowedAtImport"
            :panel="panel"
            :schema="schema"
          />
          <label :class="previewFileName ? 'import-file-button file-label has-a-file' : 'import-file-button file-label empty'" for="import-file-input">
            <i class="mdi mdi-file-upload" />
            <span v-if="!previewFileName">
              {{ i18n.import_a_file }}
            </span>
            <span v-else>
              {{ previewFileName }}
              ({{ i18n.change }})
            </span>
          </label>
          <input id="import-file-input" type="file" @change="previewFile">
        </div>
      </div>
    </div>
    <div v-else style="padding: 20px;">
      <b-message type="is-success" has-icon v-if="!criticalError">
        <h1>{{ i18n.import_done_successfully }}</h1>
        {{ i18n.import_done_you_can_close_this_modal }}
      </b-message>
      <br>
      <button class="button close-button" @click="$emit('input', false)">
        <i class="mdi mdi-close" />
        {{ i18n.close }}
      </button>
    </div>
  </Modal>
</template>
<script>
import { mapState } from 'vuex';
import mapObject from 'map-obj';
import Modal from '@/components/modals/Modal';
import Minifield from '@/components/Minifield';
import ImportModalWelcome from '@/components/modals/ImportModal/ImportModalWelcome';
import ImportTypeBadge from '@/components/modals/ImportModal/ImportTypeBadge';
import GenerateDataImportFileButton from '@/components/modals/GenerateDataImportFileButton';
import InlineObjectRenderer from '@/components/InlineObjectRenderer';
import deepClone from '@/core/utils/deepClone';
import Api from '@/core/Api';
import i18n from 'i18n/components/modals/ImportModal.json';
import parse from 'date-fns/parse';
import dayjs from 'dayjs';
import { getJsDateFromExcel } from 'excel-date-to-js';

function tryToString (v) {
  if (v && v.toString()) {
    return v.toString();
  } else {
    return v
  }
}
const addDaysToDate = function(startDate, days) {
  var date = new Date(startDate.valueOf());
  date.setDate(date.getDate() + days);
  return date;
}

window.$parse = parse;

const isFieldAllowedAtImport = (f, upsertKey) => {
  const allowedTypes = [
    'input',
    'text',
    'number',
    'select',
    'checkbox',
    'document',
    'week',
    'date',
    'textArea',
    'checklist',
    'customChecklist',
  ];
  if (f.hideAtImport) {
    if (upsertKey && upsertKey === f.model) {
      return true;
    }
    return false;
  }
  if ((allowedTypes.includes(f.type))) {
    return true;
  }
  return false;
};

export default {
  components: {
    Modal,
    Minifield,
    ImportModalWelcome,
    GenerateDataImportFileButton,
    InlineObjectRenderer,
    ImportTypeBadge,
  },
  props: {
    value: {
      type: Boolean,
      default: true,
    },
    panel: {
      type: Object,
      required: true,
    },
    schema: {
      type: [Object],
      default: undefined,
    },
  },
  data() {
    return {
      i18n,
      isFieldAllowedAtImport,
      showUnchanged: false,
      importProgressTotal: 0,
      importProgressMessage: undefined,
      importProgressCount: 0,
      previewFileName: undefined,
      previewData: undefined,
      criticalError: undefined,
      readingFile: false,
      importedFields: [],
      overwritenKeysFields: [],
      relationItems: {},
      selectedRows: [],
      editedRow: undefined,
      editedRowData: undefined,
      validRows: [],
      invalidRows: [],
      importedRows: {},
      previouslyInsertedRows: {},
      hiddenRows: [],
      updateStatuses: {},
      importDone: false,
      exportFunctions: {
        count: (e) => {
          if (e) {
            return e.length;
          }
        },
      },
      unchangedValuesCount: 0,
      active: this.value,
    };
  },
  computed: {
    ...mapState({
      user: (state) => state.login.user,
    }),
    selectedDataValid() {
      for (let i = 0; i < this.selectedRows.length; i++) {
        if (!this.validRows.includes(this.selectedRows[i])) {
          return false;
        }
      }
      return true;
    },
    upsertKey() {
      if (this.schema && this.schema.fields) {
        for (const f of this.schema.fields) {
          if (f.isIdentifier) {
            return f.model;
          }
        }
      }
      return undefined;
    },
    upsertKeyLabel() {
      let res;
      for (const f of this.schema.fields) {
        if (this.upsertKey && f.model === this.upsertKey) {
          res = f.label;
        }
      }
      return res;
    },
    schemaAllowedFields() {
      if (this.schema) {
        return this.schema.fields.filter((f) => isFieldAllowedAtImport(f, this.upsertKey));
      }
    },
  },
  watch: {
    active() {
      this.resetModal();
    },
    value(v) {
      this.active = v;
    },
    schema: {
      handler(v) {
        if (v && v.fields) {
          this.importedFields = v.fields.filter((f) => isFieldAllowedAtImport(f, this.upsertKey));
          if (this.panel.import && this.panel.import.overwriteKeys && this.panel.import.overwriteKeys.length) {
            this.overwritenKeysFields = [];
            for (let i = 0; i < this.panel.import.overwriteKeys.length; i++) {
              this.overwritenKeysFields.push(v.fields.filter((f) => f.model === this.panel.import.overwriteKeys[i])[0]);
            }
          }
        } else {
          this.importedFields = [];
        }
      },
      immediate: true,
    },
    editedRow(r) {
      if (r !== undefined) {
        this.editedRowData = mapObject(r, (key, value) => {
          for (let i = 0; i < this.importedFields.length; i++) {
            let newValue = value;
            if (this.importedFields[i].relation && !this.importedFields[i].onlyFieldAsString) {
              newValue = value[this.importedFields[i].labelField] || value;
            }
            if (this.importedFields[i].model === key) {
              return [this.importedFields[i].label, newValue];
            }
          }
          console.warn('unknown field', key, value);
          return [key, value];
        });
      } else {
        this.editedRowData = undefined;
      }
    },
    previewData: {
      handler() {
        this.$nextTick(() => {
          this.unchangedValuesCount = this.$el.querySelectorAll('tr.unchanged').length;
          for (let i = 0; i < this.selectedRows.length; i++) {
            const r = this.selectedRows[i];
            if (r.__insertType === 'unchanged') {
              this.selectedRows.splice(this.selectedRows.indexOf(r), 1);
              i--;
            }
          }
        });
      },
      deep: true,
    },
  },
  methods: {
    selectOnlyValidRows() {
      this.selectedRows = [];
      for (let i = 0; i < this.previewData.length; i++) {
        if (!this.selectedRows.includes(this.previewData[i]) && this.previewData[i].__insertType !== 'unchanged') {
          if (this.validRows.includes(this.previewData[i])) {
            this.selectedRows.push(this.previewData[i]);
          }
        }
      }
    },
    isRowCheckable(row, arg2, arg3) {
      if (row.__insertType === 'unchanged') {
        return false;
      }
      return true;
    },
    rowClass(row, index) {
      let cssClass = '';
      if (this.panel.import && this.upsertKey) {
        if (this.updateStatuses[index] && this.updateStatuses[index].insertType === 'unchanged') {
          if (!this.showUnchanged) {
            cssClass += 'unchanged hidden ';
          } else {
            cssClass += 'unchanged ';
          }
        }
      }
      if (this.hiddenRows.includes(row)) {
        cssClass += 'hidden';
      } else if (this.validRows.includes(row)) {
        cssClass += 'valid';
      } else if (this.invalidRows.includes(row)) {
        cssClass += 'invalid';
      }
      return cssClass;
    },
    toggleSelectAll() {
      if (this.selectedRows.length < this.previewData.length) {
        for (let i = 0; i < this.previewData.length; i++) {
          if (!this.selectedRows.includes(this.previewData[i]) && this.previewData[i].__insertType !== 'unchanged') {
            this.selectedRows.push(this.previewData[i]);
          }
        }
      } else {
        this.selectedRows = [];
      }
    },
    editRow(row) {
      const indexOfRow = this.previewData.indexOf(row);
      const extractedRow = this.extractRow(this.editedRowData, indexOfRow);
      const extractedRowKeys = Object.keys(extractedRow);
      for (let i = 0; i < extractedRowKeys.length; i++) {
        row[extractedRowKeys[i]] = extractedRow[extractedRowKeys[i]];
      }
      if (this.invalidRows.includes(extractedRow) && !this.invalidRows.includes(row)) {
        this.invalidRows.push(row);
      }
      if (this.validRows.includes(extractedRow) && !this.validRows.includes(row)) {
        this.validRows.push(row);
      }
      if (!this.invalidRows.includes(extractedRow) && this.invalidRows.includes(row)) {
        this.invalidRows.splice(this.invalidRows.indexOf(row), 1);
      }
      if (!this.validRows.includes(extractedRow) && this.validRows.includes(row)) {
        this.validRows.splice(this.validRows.indexOf(row), 1);
      }
      if (this.validRows.includes(extractedRow)) {
        this.validRows.splice(this.validRows.indexOf(extractedRow), 1);
      }
      if (this.invalidRows.includes(extractedRow)) {
        this.invalidRows.splice(this.invalidRows.indexOf(extractedRow), 1);
      }

      this.editedRow = undefined;
    },
    toggleSelected(row) {
      if (!this.selectedRows.includes(row)) {
        this.selectedRows.push(row);
      } else {
        this.selectedRows.splice(this.selectedRows.indexOf(row), 1);
      }
    },
    async importData() {
      try {
        this.importProgressMessage = 'Préparation de l\'import';
        this.importProgressTotal = this.previewData.length;
        this.importProgressCount = 0;

        this.importProgressCount = 1;
        let resImportDocument;
        try {
          resImportDocument = await this.$store.dispatch(`${this.panel.storeModule || 'abstractElements'}/createObject`, {
            collection: 'system_imports',
            object: {
              date: new Date().toJSON(),
              type: 'excel',
              collection: this.panel.collection,
              documentsCount: this.selectedRows.length,
              username: this.user.username,
              panel: this.panel._id,
            },
          });
        } catch (e) {
          this.criticalError = { message: i18n.could_not_save_to_system_import };
          this.importDone = true;
        }

        if (resImportDocument.data.success === true) {
          this.importProgressMessage = 'Préparation des enregistrements';

          let addedRows = [];
          let updatedRows = [];
          const upsertKeyModel = this.upsertKey;
          if (upsertKeyModel) {
            for (let i = 0; i < this.previewData.length; i++) {
              if (this.selectedRows.includes(this.previewData[i])) {
                if (this.updateStatuses[i] && this.updateStatuses[i].insertType === 'add') {
                  addedRows.push(this.previewData[i]);
                } else if (this.updateStatuses[i] && this.updateStatuses[i].insertType === 'edit') {
                  for (let k = 0; k < this.schemaAllowedFields.length; k++) {
                    const f = this.schemaAllowedFields[k];
                    if (this.previewData[i][f.model] === undefined) {
                      this.previewData[i][f.model] = null;
                    }
                  }

                  // FIXME bug here when values goes to undefined
                  const newRowToUpdate = {
                    ...this.previouslyInsertedRows[this.previewData[i][upsertKeyModel]],
                    ...this.previewData[i],
                  };
                  updatedRows.push(newRowToUpdate);
                }
              }
            }
          } else {
            for (let j = 0; j < this.previewData.length; j++) {
              if (this.selectedRows.includes(this.previewData[j])) {
                addedRows.push(this.previewData[j]);
              }
            }
          }


          let resCreated;
          let resUpdated;
          if (addedRows && addedRows.length) {
            this.importProgressMessage = 'Création des nouveaux enregistrements';

            addedRows = addedRows.map((r) => {
              const newRow = deepClone(r);
              delete newRow.__importErrors;
              return newRow;
            });
            resCreated = await this.$store.dispatch(`${this.panel.storeModule || 'abstractElements'}/createObject`, {
              collection: this.panel.collection,
              destinationBucket: this.panel.bucket,
              objects: addedRows,
              sort: this.panel.sort,
              schema: this.schema,
            });
          }

          if (updatedRows && updatedRows.length) {
            this.importProgressMessage = 'Mise à jour des enregistrements existants';
            updatedRows = updatedRows.map((r) => {
              const newRow = deepClone(r);
              delete newRow.__importErrors;
              return newRow;
            });
            resUpdated = await this.$store.dispatch(`${this.panel.storeModule || 'abstractElements'}/saveObject`, {
              collection: this.panel.collection,
              destinationBucket: this.panel.bucket,
              objects: updatedRows,
              sort: this.panel.sort,
              schema: this.schema,
            });
          }

          for (const payload of [resCreated, resUpdated]) {
            if (payload && payload.data && payload.data.success === false) {
              console.error('error', payload);
              this.criticalError = payload.data.error;
            }
          }
          this.importProgressTotal = 0;
          this.importProgressMessage = undefined;
          this.importDone = true;
        } else {
          this.criticalError = { message: i18n.could_not_save_to_system_import };
          this.importProgressMessage = undefined;
          this.importDone = true;
        }
      } catch (e) {
        console.error(e);
        this.criticalError = { message: 'Unknown error' };
        this.importProgressMessage = undefined;
        this.importDone = true;
      }
    },
    resetModal() {
      this.importDone = false;
      this.previewData = undefined;
      this.previewFileName = undefined;
      this.criticalError = undefined;
      this.readingFile = false;
      this.validRows = [];
      this.invalidRows = [];
      this.editedRow = undefined;
      this.importProgressCount = 0;
      this.importProgressMessage = undefined;
      this.selectedRows = [];
    },
    prettyPrintValue(value) {
      if (Array.isArray(value)) {
        return value.join(',');
      }
      return value;
    },
    extractRow(r, index) {
      const previouslyInsertedRow = this.previouslyInsertedRows[r[this.upsertKeyLabel]] || this.previouslyInsertedRows[parseInt(r[this.upsertKeyLabel])];
      const hasFieldChanged = (f) => {
        if (!previouslyInsertedRow) {
          return true;
        }
        let previousValue = previouslyInsertedRow[f.model];
        let newValue = r[f.label];
        if (newValue === '' || newValue === null) {
          newValue = undefined;
        }
        if (previousValue === '' || previousValue === null) {
          previousValue = undefined;
        }
        if (previousValue === newValue) {
          return false;
        }
        return true;
      };
      this.importedRows[index] = r;
      const fields = this.importedFields;
      let newRow = {};
      if (this.schema.defaultValue) {
        newRow = { ...this.schema.defaultValue, ...newRow };
      }
      if (this.panel.defaultValue) {
        newRow = { ...this.panel.defaultValue, ...newRow };
      }
      const errors = [];
      for (let i = 0; i < fields.length; i++) {
        if (newRow[fields[i].model] === null) {
          delete newRow[fields[i].model];
        }
        if (r[fields[i].label] === null) {
          delete r[fields[i].label];
        }
        if (r[fields[i].label] !== undefined) {
          newRow[fields[i].model] = r[fields[i].label];
        }
        if (newRow[fields[i].model] === '#UNCHANGED') {
          newRow[fields[i].model] = previouslyInsertedRow[fields[i].model];
        } else {
          if (fields[i].type === 'text' && typeof newRow[fields[i].model] === Number) {
            newRow[fields[i].model] = parseInt(newRow[fields[i].model]);
          } else if (typeof newRow[fields[i].model] === 'number' && isNaN(newRow[fields[i].model])) {
            newRow[fields[i].model] = undefined;
          } else if (typeof newRow[fields[i].model] === 'string' && fields[i].type === 'number' || fields[i].inputType === 'number') {
            newRow[fields[i].model] = parseInt(newRow[fields[i].model]);
          }
          if (fields[i].type === 'date') {
            if (typeof newRow[fields[i].model] === 'number') {
              newRow[fields[i].model] = dayjs(getJsDateFromExcel(newRow[fields[i].model])).format('YYYY-MM-DD hh:mm');
            } else if(typeof newRow[fields[i].model] === 'string') {
              if (newRow[fields[i].model].indexOf('-') !== -1) {
                newRow[fields[i].model] = dayjs(newRow[fields[i].model], 'YYYY/MM/DD').format('YYYY-MM-DD hh:mm');
              } else {
                newRow[fields[i].model] = dayjs(newRow[fields[i].model], 'DD/MM/YY').format('YYYY-MM-DD hh:mm');
              }
            }
          } else if (fields[i].type === 'checkbox') {
            if (newRow[fields[i].model] === 'true' || newRow[fields[i].model] === true) {
              newRow[fields[i].model] = true;
            } else if (newRow[fields[i].model] === 'false' || newRow[fields[i].model] === false) {
              newRow[fields[i].model] = false;
            } else {
              newRow[fields[i].model] = undefined;
            }
          }
          if ((fields[i].type === 'checklist' || fields[i].type === 'customChecklist') && typeof newRow[fields[i].model] === 'string' && newRow[fields[i].model] !== '') {
            newRow[fields[i].model] = newRow[fields[i].model].split(',').map((s) => {
              const newValue = s.trim();
              const checklistValues = fields[i].values.map((c) => (typeof c === 'object' ? c.value : c));
              if (checklistValues && !checklistValues.includes(newValue)) {
                errors.push(`${i18n.import_has_error_on_field + fields[i].model + i18n.field_value_is_not_on_proposed_values}(${newValue})`);
              }
              return newValue;
            });
          }
          if (fields[i].relation && !fields[i].onlyFieldAsString && newRow[fields[i].model]) {
            const { collection } = fields[i].relation;
            const foundItems = this.relationItems[collection].filter((relationRow) => tryToString(newRow[fields[i].model]) === tryToString(relationRow[fields[i].labelField]));
            let relationItem = foundItems[0];
            if (fields[i].onlyFields && foundItems.length !== 0) {
              const relationItemCopy = { ...relationItem };
              relationItem = { _id: relationItemCopy._id };
              for (let j = 0; j < fields[i].onlyFields.length; j++) {
                relationItem[fields[i].onlyFields[j]] = relationItemCopy[fields[i].onlyFields[j]];
              }
            }
            if (relationItem) {
              newRow[fields[i].model] = relationItem;
            } else if (hasFieldChanged(fields[i])) {
              newRow[fields[i].model] = r[fields[i].label];
              newRow[fields[i].model] = `##Invalid Data## (${r[fields[i].label]})`;
              this.error = 'Invalid data';
              errors.push(i18n.import_has_error_on_field + fields[i].model + i18n.field_value_is_invalid);
            }
          }
          if (fields[i].required && (newRow[fields[i].model] === undefined || newRow[fields[i].model] === '')) {
            if (hasFieldChanged(fields[i])) {
              errors.push(i18n.import_has_error_on_field + fields[i].label + ' (' + fields[i].model + ')' + i18n.field_is_required);
            }
          }
        }
      }
      newRow.__importErrors = errors;
      if (errors && errors.length) {
        this.setRowInvalid(newRow);
      } else {
        this.setRowValid(newRow);
      }
      if (this.panel.import && this.panel.import.overwriteKeys && this.panel.defaultValue) {
        for (let i = 0; i < this.panel.import.overwriteKeys.length; i++) {
          newRow[this.panel.import.overwriteKeys[i]] = this.panel.defaultValue[this.panel.import.overwriteKeys[i]];
        }
      }
      newRow.importData = {
        date: new Date().toJSON(),
        type: 'excel document',
      };
      return newRow;
    },
    setRowValid(row) {
      const search = this.invalidRows.indexOf(row);
      if (search !== -1) {
        this.invalidRows.splice(search, 1);
      }
      if (!this.validRows.includes(row)) {
        this.validRows.push(row);
      }
    },
    setRowInvalid(row) {
      const search = this.validRows.indexOf(row);
      if (search !== -1) {
        this.validRows.splice(search, 1);
      }
      if (!this.invalidRows.includes(row)) {
        this.invalidRows.push(row);
      }
    },
    removeRow(row) {
      if (this.selectedRows.includes(row)) {
        this.selectedRows.splice(this.selectedRows.indexOf(row), 1);
      }
      if (this.validRows.includes(row)) {
        this.validRows.splice(this.validRows.indexOf(row), 1);
      }
      if (this.invalidRows.includes(row)) {
        this.invalidRows.splice(this.invalidRows.indexOf(row), 1);
      }
      if (!this.hiddenRows.includes(row)) {
        this.hiddenRows.push(row);
      }
    },
    async previewFile(event) {
      //const XLSX = await import(/* webpackChunkName: "xlsx" */ 'xlsx');
      await this.$loadScript('https://cdnjs.cloudflare.com/ajax/libs/xlsx/0.15.6/xlsx.full.min.js')
      this.editedRowIndex = undefined;
      this.validRows = [];
      this.invalidRows = [];
      this.previouslyInsertedRows = {};
      this.resetModal();
      try {
        const reader = new FileReader();
        reader.onload = () => {
          this.readingFile = true;
          this.$nextTick(async () => {
            try {
              const fileData = reader.result;
              const wb = XLSX.read(fileData, { type: 'binary' });
              const relationItems = {};
              const { fields } = this.schema;
              let upsertKeyLabel;
              let upsertKeyModel;
              if (this.upsertKey) {
                upsertKeyModel = this.upsertKey;
              }
              for (const f of fields) {
                if (f.relation && !f.onlyFieldAsString) {
                  const payload = await Api.get(`/${f.relation.collection}`);
                  relationItems[f.relation.collection] = payload.data.documents;
                }
                if (upsertKeyModel && f.model === this.upsertKey) {
                  upsertKeyLabel = f.label;
                }
              }
              this.relationItems = relationItems;
              this.importedRows = {};
              wb.SheetNames.forEach(async (sheetName) => {
                let rowObj = XLSX.utils.sheet_to_row_object_array(wb.Sheets[sheetName]);
                const allUpsertKeys = [];
                const upsertKeyField = this.importedFields.filter((f) => f.label === upsertKeyLabel)[0];
                rowObj.map((r, index) => {
                  if (upsertKeyLabel) {
                    let upsertKeyValue = r[upsertKeyLabel];
                    if (upsertKeyField.inputType === 'number' || upsertKeyField.type === 'number') {
                      upsertKeyValue = parseInt(upsertKeyValue);
                    }
                    allUpsertKeys.push(upsertKeyValue);
                  }
                });
                if (allUpsertKeys && this.panel.import && this.panel.import.upsertKey) {
                  let upsertKeysCheckRes;
                  try {
                    upsertKeysCheckRes = await Api.get(`/${this.panel.collection}`, {
                      params: {
                        query: { [this.panel.import.upsertKey]: { $in: allUpsertKeys } },
                      },
                    });
                  } catch (e) {
                    this.errorAtPreview({ error: e, location: 'upsertKeysCheck' });
                    return;
                  }
                  upsertKeysCheckRes = upsertKeysCheckRes.data;
                  for (let i = 0; i < upsertKeysCheckRes.documents.length; i++) {
                    this.previouslyInsertedRows[upsertKeysCheckRes.documents[i][upsertKeyModel]] = upsertKeysCheckRes.documents[i];
                  }
                }
                rowObj = rowObj.map((r, index) => this.extractRow(r, index));
                this.previewData = rowObj;
                this.toggleSelectAll();
              });
            } catch (e) {
              this.errorAtPreview({ error: e, location: '@2' });
            }
          });
        };
        reader.readAsBinaryString(event.target.files[0]);
        this.previewFileName = event.target.files[0].name;
      } catch (e) {
        this.errorAtPreview({ error: e, location: 'fileRead' });
      }
      this.readingFile = true;
    },
    errorAtPreview({ error, location }) {
      this.readingFile = false;
      console.error('errorAtPreview', error, location);
      error.location = location;
      this.criticalError = { message: error.message, location };
    },
    getSchemaByName(schemaName) {
      const schemas = this.$store.state.abstractElements.objects.schemas.objects;
      for (let i = 0; i < schemas.length; i++) {
        if (schemas[i].name === schemaName) {
          return schemas[i];
        }
      }
      return undefined;
    },
  },
};
</script>
<style scoped>
.import-modal.importing >>> .modal-card-body {
  margin-top: 64px;
}
.import-status-message {
  position: absolute;
  top: 45px;
  left: 0;
  right: 0;
  z-index: 200;
}

.import-modal >>> .table-wrapper.has-sticky-header {
  height: calc(85vh - 100px);
  margin-bottom: 0;
}
.import-modal >>> .pagination .pagination-link {
  margin: 0;
  margin-left: 10px;
}

.import-modal >>> tr.invalid td {
  background: #ffe1e1;
}
.import-modal >>> tr.valid td {
  background: #e1ffe8;
}

.import-modal >>> .pagination {
  margin: 0;
  margin-top: 14px;
  padding-right: 20px;
}

.import-status-message >>> .media-left {
  height: 1px;
  margin-top: -10px;
}
.import-status-message >>> .media-content > span {
  display: inline-block;
  padding-top: 2px;
}
.import-status-message >>> .media-left .mdi::before {
  font-size: 26px;
}

.table-header > th {
  position: sticky;
  top: 0;
  background: white;
  z-index: 101;
  border-bottom: 1px solid #dbdbdb;
}

.import-status-message.is-danger >>> .message-body {
  background: #cd0930;
  border: 0;
  border-radius: 0;
  color: white;
}
.import-status-message.is-warning >>> .message-header {
  padding: 26px;
}

.import-status-message.is-warning >>> .message-body {
  background: #ffdd57;
  border: 0;
  border-radius: 0;
  color: black;
}
.import-status-message.is-warning >>> .dropdown-trigger {
  font-size: 80%;
  text-decoration: underline;
  padding: 4px;
  display: block;
}

.import-status-message.is-success >>> .message-body {
  background: #278247;
  border: 0;
  border-radius: 0;
  color: white;
}

.file-label {
  border-radius: 4px;
  background: #7957d5;
  display: inline-block;
  margin-top: 20px;
  color: white;
  cursor: pointer;
  padding: 10px;
}
.file-label.has-a-file {
  background: white;
  border: 1px solid #7957d5;
  color: #7957d5;
}
.file-label:hover {
  background: #714dd2;
  color: white;
}
#import-file-input {
  display: none;
}
.import-data-button {
  margin-top: -6px;
  float: right;
}

.import-preview-table {
  display: block;
  font-size: 75%;
}

.preview-table {
  font-size: 75%;
}

.import-preview-table tr {
  width: calc(100% + 40px);
}
.row-validity {
  color: white;
}
.import-preview-table-right-toolbar > div {
  height: 31px;
  padding-top: 3px;
}
.row-validity i {
  border-radius: 100% 0 0 100%;
  padding-left: 7px;
  padding-top: 5px;
  padding-bottom: 5px;
  padding-right: 1px;
}
.row-validity i.mdi-close {
  background: #ff3860;
}
.row-validity i.mdi-check {
  background: #23d160;
}
.checkbox, td >>> .checkbox {
  padding-top: 8px;
}
.import-modal >>> .tab-content {
  padding: 0;
  padding-right: 70px;
}

.import-modal >>> .message.preview-container {
  margin: 0;
}

.import-modal >>> .is-sticky {
  z-index: 100 !important;
}
.import-modal >>> .table-wrapper.has-sticky-header tr:first-child th {
  z-index: 200 !important;
}
tr.selected {
  background: #f8f7fd;
}
.import-type-icon {
  display: inline-block;
  position: relative;
  height: 12px;
  width: 12px;
  margin-left: -10px;
  margin-top: -10px;
}
.import-type-icon i {
  position: absolute;
  top: -4px;
  left: 0;
}
.edit-document-button {
  padding: 0;
  height: 20px;
  width: 20px;
  margin-top: 5px;
  margin-left: -4px;
  margin-right: 3px;
  position: relative;
}
.edit-document-button i {
  position: absolute;
  top: -3px;
  left: 2px;
}

td {
  position: relative;
}
td, th {
  white-space: nowrap;
}
tr.edited td {
  padding: 0;
  margin: 0;
}
td >>> .row-value-input {
  position: relative;
  height: 10px;
  display: inline-block;
  max-width: fit-content;
  width: 100%;
  height: 30px;
  padding-left: 9px;
  padding-right: 9px;
  min-width: 50px;
}
td >>> .select-input select {
  border: 1px solid rgb(175, 175, 175);
  border-radius: 0;
  height: 30px;
  padding-top: 1px;
}

.import-modal >>> .hidden {
  display: none;
}
.required {
  color: red;
  font-weight: bold;
}
td.sticky-col-left {
  position: sticky;
  left: 0px;
  background: white;
  z-index: 100;
}
.sticky-col-right {
  position: sticky;
  z-index: 100;
  right: 0;
  padding-right: 0;
}
.import-modal >>> .import-actions-cell > span {
  display: flex;
}

.row-value, .preview-table >>> th {
  white-space: nowrap;
  padding-right: 20px;
}
.import-file-button {
  margin-top: 60px;
  margin-left: 20px;
}
.modal-title {
  width: 100%;
}
</style>
<style>
.too-many-rows-dropdown .dropdown-content {
  padding: 20px;
  white-space: pre-line;
  font-size: 80%;
}
</style>
