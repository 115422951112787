const schema = {
  fields: [{
    label: 'Type de vue',
    type: 'select',
    values: ['list', 'kanban', 'twowayskanban', 'calendar', 'array', 'gantt'],
    styleClasses: [ 'no-margin' ],
  }, {
    type: 'schemapropertyselector',
    label: 'Group by',
    model: 'component.categoryField',
    visible: (m) => m?.component && (m.component.name === 'kanban' || m.component.name === 'twowayskanban'),
    styleClasses: [ 'sub-field' ],
  }, {
    type: 'Array',
    label: 'Colonnes',
    model: 'component.categories',
    showRemoveButton: true,
    itemContainerComponent: 'FieldArrayItemContainer',
    items: {
      type: 'text',
    },
    visible: (m) => m?.component && (m.component.name === 'kanban' || m.component.name === 'twowayskanban'),
    styleClasses: [ 'sub-field' ],
  }, {
    type: 'text',
    label: 'Champ titre',
    model: 'component.titleKey',
    visible: (m) => m?.component && (m.component.name === 'calendar' || m.component.name === 'gantt') && m.schema === undefined,
    styleClasses: [ 'sub-field' ],
  }, {
    type: 'schemapropertyselector',
    label: 'Champ titre',
    model: 'component.titleKey',
    schemaName: (m) => m.schema,
    visible: (m) => m?.component && (m.component.name === 'calendar' || m.component.name === 'gantt') && m.schema !== undefined,
    styleClasses: ['sub-field'],
  }, {
    type: 'text',
    label: 'Champ date',
    model: 'component.dateKey',
    visible: (m) => m?.component && m.component.name === 'calendar' && m.schema === undefined,
    styleClasses: [ 'sub-field' ],
  }, {
    type: 'schemapropertyselector',
    label: 'Champ date',
    model: 'component.dateKey',
    schemaName: (m) => m.schema,
    visible: (m) => m?.component && m.component.name === 'calendar' && m.schema !== undefined,
    styleClasses: ['sub-field'],
  }, {
    type: 'text',
    label: 'Champ couleur',
    model: 'component.colorKey',
    visible: (m) => m?.component && m.component.name === 'calendar' && m.schema === undefined,
    styleClasses: [ 'sub-field' ],
  }, {
    type: 'schemapropertyselector',
    label: 'Champ couleur',
    model: 'component.colorKey',
    schemaName: (m) => m.schema,
    visible: (m) => m?.component && m.component.name === 'calendar' && m.schema !== undefined,
    styleClasses: ['sub-field'],
  }, {
    type: 'text',
    label: 'Champ start date',
    model: 'component.startDateKey',
    visible: (m) => m?.component && (m.component.name === 'calendar' || m.component.name === 'gantt') && m.schema === undefined,
    styleClasses: [
    'sub-field',
    ],
  }, {
    type: 'schemapropertyselector',
    label: 'Champ start date',
    model: 'component.startDateKey',
    schemaName: (m) => m.schema,
    visible: (m) => m?.component && (m.component.name === 'calendar' || m.component.name === 'gantt') && m.schema !== undefined,
    styleClasses: ['sub-field'],

  }, {
    type: 'text',
    label: 'Champ end date',
    model: 'component.endDateKey',
    visible: (m) => m?.component && (m.component.name === 'calendar' || m.component.name === 'gantt') && m.schema === undefined,
    styleClasses: [
    'sub-field',
    ],
  }, {
    type: 'schemapropertyselector',
    label: 'Champ end date',
    model: 'component.endDateKey',
    schemaName: (m) => m.schema,
    visible: (m) => m?.component && (m.component.name === 'calendar' || m.component.name === 'gantt') && m.schema !== undefined,
    styleClasses: ['sub-field'],
  }, {
    type: 'text',
    label: 'Champ dépendence',
    model: 'component.dependencyKey',
    visible: (m) => m?.component && m.component.name === 'gantt' && m.schema === undefined,
    styleClasses: [ 'sub-field' ],
  }, {
    type: 'schemapropertyselector',
    label: 'Champ tâche précédente',
    model: 'component.previousTaskKey',
    schemaName: (m) => m.schema,
    visible: (m) => m?.component && m.component.name === 'gantt' && m.schema !== undefined,
    styleClasses: ['sub-field']
  }, {
    type: 'schemapropertyselector',
    label: 'Champ completion',
    model: 'component.completionKey',
    schemaName: (m) => m.schema,
    visible: (m) => m?.component && m.component.name === 'gantt',
    styleClasses: ['sub-field']
  }, {
    type: 'schemaproperties',
    label: 'Champs latéraux',
    model: 'component.sidebarFields',
    schemaName: (m) => m.schema,
    visible: (m) => m?.component && m.component.name === 'gantt',
    styleClasses: ['sub-field']
  }],
};

const visibleForBlocks = (model, blocks) => {
  return model?.component?.name && blocks.indexOf(m.component.name) === 1;
};

export default schema;
