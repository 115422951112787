<template>
  <section :class="`pagination-bar ${isMobile ? 'mobile': 'desktop'}`">
    <div class="pagination-wrapper">
      <b-select
        v-model="$parent.paginationPerPage"
        class="per-page-select"
        :placeholder="i18n.per_page"
        @input="onPaginationChange"
      >
        <option
          v-for="option in [5,7,8,10,20,30,50,100]"
          :key="option"
          :value="option"
        >
          {{ option }}
        </option>
      </b-select>
      <b-pagination
        :simple="isMobile"
        :total="total"
        :current.sync="$parent.paginationPage"
        :per-page="$parent.paginationPerPage"
        :aria-next-label="i18n.next_page"
        :aria-previous-label="i18n.previous_page"
        :aria-page-label="i18n.page"
        :aria-current-label="i18n.current_page"
        @change="onPageChange"
      />
      <span v-if="!isMobile" class="pagination-total">
        {{ i18n.total }} : {{ total }}
      </span>
    </div>
  </section>
</template>
<script>
import { isMobile } from 'mobile-device-detect';
import i18n from 'i18n/components/PaginationControls.json';
import redirect from '@/core/utils/redirect';

export default {
  name: 'PaginationControls',
  data() {
    return {
      i18n,
      isMobile,
    };
  },
  methods: {
    onPaginationChange (perPage) {
      this.$store.commit('listPagination/saveListPagination', perPage);
      redirect(this.$router, [{ query: { ...this.$route.query, perPage } }]);
    },
    onPageChange (page) {
      redirect(this.$router, [{ query: { ...this.$route.query, page } }]);
    },
  },
  computed: {
    total () {
      if (this.$parent.allElements) {
        return this.$parent.allElements.total || this.$parent.paginatedFilteredElementsTotal;
      }
    }
  }
};
</script>
<style scoped>
section >>> li {
    list-style-type: none;
}
.pagination-wrapper {
  width: 100%;
  max-width: 960px;
  padding-left: 40px;
  margin: auto;
  display: flex;
  justify-content: space-between;
}

.pagination-bar >>> .pagination-list,
.pagination {
  margin: 0;
  padding: 0;
}

.pagination-bar >>> .pagination-previous,
.pagination-bar >>> .pagination-next,
.pagination-bar >>> .pagination-link,
section >>> li {
  padding-top: 0;
  padding-bottom: 0;
  margin-top: 0;
  margin-bottom: 0;
}

.per-page-select {
  margin-top: 2px;
}

.pagination-bar {
  position: fixed;
  padding-right: 120px;
  padding-left: 20px;
  padding-bottom: 2px;
  bottom: 0;
  right: 0;
  width: calc(100% - 300px);
  margin-right: 0;
  background: whitesmoke;
  box-shadow: 0 -2px 3px rgba(10, 10, 10, 0.1), 0 0 0 1px rgba(10, 10, 10, 0.1);
}
.pagination-bar.mobile {
  width: 100%;
  padding-left: 0px;
}
.pagination-bar.mobile .pagination-wrapper {
  padding-left: 0;
  justify-content: space-around;
}
.pagination-total {
  margin-top: 2px;
  font-size: 120%;
  background: #e4e4e4;
  padding: 6px;
  display: inline;
  height: 40px;
  border-radius: 10px;
}

.pagination-bar >>> .pagination-list {
  width: 350px;
}
</style>
<style>
.menu-hidden .pagination-bar {
  width: 100%;
}
</style>
