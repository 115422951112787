<template>
  <div>
    <section class="element-list-panel table-view" style="position: relative;">
      <PanelTitleBar
        v-if="!hideTitleBar"
        :panel="config"
        :panel-params="panelParams"
        :table-view-mode="$parent.tableViewMode"
        :hideEditButton="hideEditButton"
        @setTableViewMode="$parent.tableViewMode = $event"
      >
        <v-runtime-template v-if="config.header" :template="`<span>${config.header}</span>`" />
      </PanelTitleBar>
      <slot name="search" />
      <b-message v-if="allElements && allElements.error" type="is-danger" has-icon>
        <b>Error</b><br/>
        <span v-if="allElements.error && allElements.error.message">
          {{allElements.error.message}}
        </span>
      </b-message>
      <div v-if="allElements && allElements.isLoading !== false" class="loading">
        <div v-for="i in paginationPerPage" :key="i" class="card" style="padding: 24px; display: flex">
          <span style="width: 32px;">
            <b-skeleton circle width="24px" height="24px" />
          </span>
          <span style="width: 120px; padding-left: 20px; padding-right: 20px;">
            <b-skeleton />
          </span>
          <span style="flex-grow: 1">
            <b-skeleton />
          </span>
          <span style="width: 120px; padding-left: 20px; padding-right: 20px;">
            <b-skeleton />
          </span>
          <span style="width: 22px;">
            <b-skeleton circle width="14px" height="14px" />
          </span>
        </div>
      </div>
      <div v-if="allElements && allElements.isLoading === false && elementsToDisplay" :class="`list-container`">
        <b-table hoverable narrowed bordered striped :data="elementsToDisplay">
          <b-table-column label="" width="50px">
            <template v-slot:header="{ column }">
              <b-dropdown v-model="shownColumns" multiple class="columns-dropdown">
                <a class="columns-dropdown-button" slot="trigger">
                  <i class="mdi mdi-menu mdi-18px" />
                </a>
                <b-dropdown-item v-for="k in recordKeys" :key="k.model" :value="k" class="show-columns-dropdown-item">
                  <div @click.ctrl.stop="showOnlyKey(k)">
                    <i :class="`mdi mdi-${fieldIcon(k)}`"/>&nbsp;
                    {{k.label}}
                  </div>
                </b-dropdown-item>
              </b-dropdown>
              <b-tooltip v-if="isArrayLocked" label="Mode lecture seule" position="is-bottom" class="main-locked-icon">
                <i class="mdi mdi-lock"/>
              </b-tooltip>
            </template>
            <template v-slot="props">
              <a @click="editRow(props.row)" v-if="!hideEditButton" style="padding: 14px; padding-top: 3px; padding-bottom: 3px; display: block;">
                <i class="mdi mdi-pencil mdi-18px"/>
              </a>
            </template>
          </b-table-column>
          <b-table-column v-for="k in recordKeys" :key="k.model" :visible="shownColumns.indexOf(k) !== -1" :field="k.model" :cell-class="`type-${k.type}`">
            <template v-slot:header="{ column }">
              <b-tooltip :label="k.type" position="is-bottom">
                <i :class="`mdi mdi-${fieldIcon(k)}`"/>
              </b-tooltip>
              <a class="sort-link" @click="sortBy(k.model)">{{k.model}}</a>
              <i class="mdi mdi-arrow-down" v-if="JSON.stringify(panelSort) === JSON.stringify({[k.model]: 1})"/>
              <i class="mdi mdi-arrow-up" v-if="JSON.stringify(panelSort) === JSON.stringify({[k.model]: -1})"/>
            </template>
            <template v-slot="props">
              <CellEditor
                v-if="editedRowIndex === props.index && editedColName === k.model"
                :value="props.row[k.model]"
                :colSchema="k"
                :class="`edited ${isLoadingAfterEdit ? 'loading': ''}`"
                @input="changeCellValue($event)"
              />
              <template v-else>
                <CellValue
                  :row="props.row"
                  :rowIndex="props.index"
                  :colSchema="k"
                  :class="`with-cell-padding ${isFieldEditable(k) || isArrayLocked ? '': 'locked'} ${isLoadingAfterEdit && editedRowIndex === props.index ? 'row-saving':''}`"
                  @edit="editCell($event.rowIndex, $event.model)"
                  @filter="filter($event.model, $event.value)"
                />
              </template>
            </template>
          </b-table-column>
        </b-table>
        <slot :elements="elements" name="footer" />
      </div>
      <div v-else-if="allElements && !allElements.isLoading" style="text-align: center;">
        <div class="content has-text-grey has-text-centered" style="margin-top: 200px">
          <p>Pas de résultat trouvé</p>
        </div>
      </div>
    </section>
    <pagination-controls v-if="!hidePagination" @onPerPageChange="onPerPageChange" @onPageChange="onPageChange" />
  </div>
</template>
<script>
import Vue from 'vue';

import AbstractElementList from '@/components/AbstractElementList';
import PaginationControls from '@/components/PaginationControls';
import Pagination from '@/core/mixins/pagination.js';
import deepClone from '@/core/utils/deepClone';
import TruncatedText from './components/TruncatedText';
import CellValue from './Array/CellValue';
import CellEditor from './Array/CellEditor';
import vfgFields from '@/core/vfgfields';
import { mapState } from 'vuex';
import VRuntimeTemplate from 'v-runtime-template';
import PanelTitleBar from '@/components/PanelTitleBar';
import redirect from '@/core/utils/redirect';

const allowedTypes = [
  'input',
  'text',
  'number',
  'select',
  'checkbox',
  'week',
  'date',
  'textArea',
  'checklist',
  'customChecklist',

];

export default {
  components: {
    PanelTitleBar,
    VRuntimeTemplate,
    PaginationControls,
    TruncatedText,
    CellEditor,
    CellValue,
  },
  props: {
    hideTitleBar: {
      type: Boolean,
      default: false,
    },
    locked: {
      type: Boolean,
      default: true,
    },
    disableCellFiltering: {
      type: Boolean,
      default: false,
    VRuntimeTemplate,
    },
    hideEditButton: {
      type: Boolean,
      default: false,
    },
    hidePagination: {
      type: Boolean,
      default: false,
    },
    showEverything: {
      type: Boolean,
      default: false,
    }
  },
  extends: AbstractElementList,
  mixins: [Pagination],
  data () {
    return {
      isLoadingAfterEdit: false,
      editedRowIndex: undefined,
      editedColName: undefined,
      columnsDisplayed: undefined,
    };
  },
  computed: {
    panelSort: {
      get () {
        return this.$store.state.currentPanel.currentPanelSort;
      },
      set (v) {
        this.$store.commit('currentPanel/setCurrentPanelSort', v);
      }
    },
    isArrayLocked () {
      return this.locked === true || this.config.locked === true;
    },
    recordKeys() {
      if (this.schema && this.schema.fields) {
        return this.schema.fields;
      }
      return [];
    },
    shownColumns: {
      get () {
        if (this.columnsDisplayed) {
          return this.columnsDisplayed;
        }
        return [...this.recordKeys];
      },
      set (v) {
        this.columnsDisplayed = v;
      }
    },
    elementsToDisplay () {
      if (this.allElements && this.showEverything) {
        return this.allElements.objects;
      } else {
        return this.paginatedFilteredElements;
      }
    }
  },
  methods: {
    showOnlyKey (key) {
      this.shownColumns = [key];
    },
    sortBy (model) {
      if (JSON.stringify(this.panelSort) === JSON.stringify({ [model]: 1 })) {
        this.panelSort = { [model]: -1 };
      } else {
        this.panelSort = { [model]: 1 }
      }
    },
    editRow(row) {
      this.$emit('onEdit', { element: row, panel: this.config });
    },
    filter(model, value) {
      if (this.disableCellFiltering !== true) {
        redirect(this.$router, [{ query: { ...this.$route.query, searchText: `{"${model}": ${JSON.stringify(value)} }` } }]);
      }
    },
    isFieldEditable (f) {
      return allowedTypes.includes(f.type) && !f.disabled && !f.readonly;
    },
    editCell(rowIndex, colName) {
      if (!this.isArrayLocked) {
        let field = this.schema.fields.filter(f => f.model === colName);
        if (field && field.length) {
          field = field[0];
        }
        if (this.isFieldEditable(field)) {
          this.editedRowIndex = rowIndex;
          this.editedColName = colName;
        }
      }
    },
    changeCellValue(cellValue) {
      console.log('start changeCellValue', this.editedRowIndex, this.editedColName);
      if (this.editedRowIndex !== undefined && this.editedColName) {
        console.log('start changeCellValue');
        const newRow = deepClone(this.paginatedFilteredElements[this.editedRowIndex]);
        newRow[this.editedColName] = cellValue;
        this.isLoadingAfterEdit = true;
        const storeModule = this.config.storeModule || 'abstractElements';
        this.$store.dispatch(`${storeModule}/saveObject`, {
          collection: this.config.collection,
          destinationBucket: this.config.bucket,
          object: newRow,
          sort: this.config.sort,
        }).then((res) => {
          console.log('changeCellValue saved', this.editedRowIndex, this.editedColName);
          this.$store.commit('panels/resolveEditionPromise', {
            object: res.payload.data.document,
          });
          this.isLoadingAfterEdit = false;
          this.editedRowIndex = undefined;
          this.editedColName = undefined;
        });
        //this.paginatedFilteredElements[this.editedRowIndex][this.editedColName] = cellValue;
      }
    },
    fieldIcon(p) {
      const item = vfgFields.fields.find((f) => f.originalLabel === p.type);
      return item ? item.icon : 'unknown';
    },
  },
};
</script>
<style scoped>
.table-view >>> table {
  border-collapse: collapse;
}

.table-view >>> th {
  font-weight: normal;
  white-space: nowrap;
  position: sticky;
  top: 0;
  background: white;
  z-index: 1;
  text-overflow: ellipsis;
  font-weight: 700;
  font-size: 16px;
  padding: 0;
}
.table-view >>> .th-wrap {
  border-bottom: 4px solid #ccc !important;
  padding: 0.25em 0.5em;
  height: 36px;
}
.table-view >>> td:first-child {
  min-width: 0;
}
.table-view >>> td {
  max-width: 300px;
  word-break: break-word;
}
.table-view >>> td.type-textArea {
  max-width: 300px;
  min-width: 300px;
}
.columns-dropdown {
  position: absolute;
  left: -36px;
}
.columns-dropdown-button {
  background: white;
  padding: 7px;
  width: 40px;
  box-shadow: 0 0.5em 1em -0.125em rgba(10, 10, 10, 0.1), 0 0px 0 1px rgba(10, 10, 10, 0.02);
  border-radius: 0;
}
.searchable-field {
  cursor: pointer;
}
.table-view >>> td {
  padding: 0;
}
.table-view >>> .edited::before {
  content: '.';
  visibility: hidden;
}
.table-view >>> .edited {
  top: 0;
  height: 100%;
  left: 0;
  width: 100%;
  list-style: none;
  display: list-item;
}
.table-view >>> .edited.loading {
  background: #ffdeae;
}
.table-view >>> .row-saving {
  background: #ffefbf;
}
.table-view >>> .edited > .minifield {
  width: 100%;
}
.with-cell-padding {
  padding: 0.25em 0.5em;
  display: inline-block;
  height: 100%;
  width: 100%;
  position: relative;
}
.with-cell-padding.locked {
  padding-left: 22px;
}
.with-cell-padding.locked::before {
  transform: scale(0.8);
  position: absolute;
  top: 4px;
  left: 0px;
  color: #ddd;
  font: normal normal normal 24px/1 "Material Design Icons";
  content: "\F033E";
}
.table-view >>> .row-value-input > input[type="text"] {
  background: none;
  font-size: 100%;
  border: 0;
  width: 100%;
  padding: 0px 0px 4px 8px;
  margin-top: -2px;
}
.columns-dropdown >>> .dropdown-menu {
  max-height: 500px;
  overflow-y: auto;
}
.main-locked-icon >>> .tooltip-content {
  z-index: 100;
}
.sort-link {
  color: #363636;
}
.sort-link:hover {
  text-decoration: underline;
}
.show-columns-dropdown-item {
  padding: 0;
}
.show-columns-dropdown-item > div {
  padding: 0.375rem 1rem;
  padding-right: 3rem
}
</style>
