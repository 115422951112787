<template>
  <span class="collaborative-user-preview">
    <span v-if="connected" style="display: flex; padding-right: 3px">
      <span style="margin-top: 4px; margin-right: 10px; margin-left: 10px; display: inline-block;">
        <i class="mdi mdi-eye"/>
      </span>
      
      <span v-if="!usersViewingWithoutMe || usersViewingWithoutMe.length === 0" style="margin-top: 4px; margin-right: 10px; margin-left: 10px; display: inline-block;">
        <i class="mdi mdi-account-off"/>
      </span>
      <b-tooltip position="is-bottom" v-for="u in usersViewingWithoutMe" :key="u" style="padding-top: 2px;">
        <img :src="`https://avatars.dicebear.com/api/initials/${u}.svg`" :alt="u" style="width: 30px; border-radius: 100%;"/>
        <template v-slot:content>
          {{u}}
        </template>
      </b-tooltip>
    </span>
    <b-tooltip v-if="!connected" position="is-bottom" type="is-danger">
      <span class="tag is-rounded is-dark">
        <i class="mdi mdi-wifi-off" />
        <span class="value">{{ usersViewingWithoutMe.length }}</span>
      </span>
      <template v-slot:content>
        <div>
          <b>Vous n'êtes pas connecté au service temps réel.</b><br/>Vous pouvez tout de même visualiser et éditer les données.
        </div>
      </template>
    </b-tooltip>
  </span>
</template>
<script>
import socket from '@/core/socket';

// TODO handle emit at reconnect

export default {
  props: {
    lockName: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      usersViewing: [],
      alreadyWatchingListener: (lockName, usernames) => {
        console.log('[socket]alreadyWatchingListener', lockName);

        for (let i = 0; i < usernames.length; i++) {
          if (this.usersViewing.indexOf(usernames[i]) === -1) {
            this.usersViewing.push(usernames[i]);
          }
        }
      },
      userStartWatchListener: (lockName, username) => {
        console.log('[socket]userStartWatchListener', lockName);

        if (this.usersViewing.indexOf(username) === -1) {
          this.usersViewing.push(username);
        }
      },
      userStopWatchListener: (lockName, username) => {
        console.log('[socket]userStopWatchListener', lockName);

        if (this.usersViewing.indexOf(username) !== -1) {
          this.usersViewing.splice(this.usersViewing.indexOf(username), 1);
        }
      },
      socketDisconnectListener: () => {
        console.log('[socket]socketDisconnectListener');

        socket.io.off('alreadyWatching', this.alreadyWatchingListener);
        socket.io.off('userStartWatch', this.userStartWatchListener);
        socket.io.off('userStopWatch', this.userStopWatchListener);
        this.connected = false;
      },
      socketReconnectListener: () => {
        console.log('[socket]socketReconnectListener');

        this.connected = true;
        this.addLock(this.lockName);
      },
    };
  },
  computed: {
    connected () {
      if (!socket.io || socket.io.disconnected) {
        return false;
      } else {
        return true;
      }
    },
    usersViewingWithoutMe() {
      if (this.usersViewing) {
        return this.usersViewing.filter((u) => u !== this.$store.state.login.user.username);
      }
      return [];
    },
  },
  beforeDestroy() {
    this.removeLock(this.lockName);
  },
  watch: {
    lockName: {
      handler(v, oldV) {
        this.removeLock(oldV);
        this.addLock(v);
      },
      immediate: true,
    },
  },
  methods: {
    removeLock(lockName) {
      try {
        socket.io.emit('stopWatch', lockName, 'panels', {});
        socket.io.off('alreadyWatching', this.alreadyWatchingListener);
        socket.io.off('userStartWatch', this.userStartWatchListener);
        socket.io.off('userStopWatch', this.userStopWatchListener);
      } catch (e) {}
    },
    addLock(lockName) {
      try {
        socket.io.emit('startWatch', lockName, 'panels', {});
        socket.io.on('alreadyWatching', this.alreadyWatchingListener);
        socket.io.on('userStartWatch', this.userStartWatchListener);
        socket.io.on('userStopWatch', this.userStopWatchListener);
      } catch (e) {}
    },
  },
};
</script>
<style scoped>
.collaborative-user-preview {
  margin-right: 10px;
  margin-top: 0;
  border: 1px solid #ccc;
  border-radius: 40px;
  height: 36px;
  display: inline-block;
}
.tag {
  margin-left: 8px;
  margin-right: 8px;
  margin-top: 4px;
}
.value {
  margin-left: 4px;
  font-size: 120%;
}
.collaborative-user-preview >>> .tooltip-content {
  z-index: 1000000000;
}
</style>
