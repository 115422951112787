<template>
  <div class="rule" @dragstart="$emit('dragStart')">
    <div class="field rule-field">
      <b-autocomplete
        v-if="schema && schema.fields"
        v-model="rule.fieldName"
        :data="schema.fields"
        :placeholder="i18n.property_name"
        field="model"
        class="fields-autocomplete"
        :open-on-focus="true"
        size="is-small"
        append-to-body
      >
        <template slot="empty">
          {{ i18n.no_results_found }}
        </template>
        <template slot-scope="props">
          {{ props.option.label }} ({{ props.option.model }})
        </template>
      </b-autocomplete>
      <input
        v-else
        v-model="rule.fieldName"
        type="text"
        class="input is-small"
        autocomplete="pff"
        name="property-name"
        :placeholder="i18n.property_name"
      >
      <b-select
        v-model="rule.operator"
        placeholder="operator"
        size="is-small"
      >
        <option
          v-for="option in operators"
          :key="option.value"
          :value="option.value"
        >
          {{ option.label }}
        </option>
      </b-select>
      <input
        v-if="rule.operator === '$regex'"
        v-model="rule.value"
        type="text"
        class="input is-small"
      >
      <Minifield
        v-else
        v-model="rule.value"
        :field="fieldValue"
        size="is-small"
      />
    </div>
    <a style="float: right; padding-right: 10px;" @click="$emit('delete')">
      <i class="mdi mdi-close" />
    </a>
  </div>
</template>
<script>
import Minifield from '@/components/Minifield';
import i18n from 'i18n/components/ui/QueryBuilder/QueryBuilderRule.json';

export default {
  name: 'QueryBuilderRule',
  components: {
    Minifield,
  },
  props: {
    rule: {
      type: Object,
      required: true,
    },
    schema: {
      type: Object,
      default: undefined,
    },
    operators: {
      type: Array,
      required: true,
    },
  },
  data() {
    return { i18n };
  },
  mounted() {
    const inputs = this.$el.querySelectorAll('input');
    for (let i = 0; i < inputs.length; i++) {
      inputs[i].setAttribute('autocomplete', 'disabled');
    }
  },
  computed: {
    fieldValue() {
      if (this.schema && this.schema.fields) {
        return this.schema.fields.filter((f) => f.model === this.rule.fieldName).concat({})[0];
      }
      return undefined;
    },
  },
};
</script>
<i18n>
{
  "en": {
    "property_name": "Property name",
    "no_results_found": "No results found"
  },
  "fr": {
    "property_name": "Nom de propriété",
    "no_results_found": "Aucun résultat trouvé"
  }
}
</i18n>
<style scoped>
.rule {
  width: 100%;
}
.rule:hover {
  background: #CCCCCC30;
}
.rule-field {
  display: inline-flex;
  margin: 0;
}
</style>
