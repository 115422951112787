/<template>
  <span class="minifield" v-click-outside="ClickOutside">
    <input
      v-if="field && field.type === 'checkbox'"
      type="checkbox"
      :value="value"
      :size="size"
      class="checkbox"
      style="margin-left: 10px;"
      @input="$emit('input', $event)"
    >
    <Checklist
      v-else-if="field && (field.type === 'checklist' || field.type === 'customChecklist')"
      :value="value"
      :choices="field.values"
      style="margin-left: 10px;"
      @input="$emit('input', $event)"
    />
    <b-input
      v-else-if="field && field.inputType === 'number'"
      :value="value"
      :size="size"
      class="row-value-input"
      type="number"
      @input="$emit('input', parseInt($event))"
      @keyup.enter="$emit('keyUpEnter')"
    />
    <b-select v-else-if="field && field.values" :value="value" :size="size" class="select-input" @input="inputOnSelect">
      <option
        v-for="option in field.values"
        :key="JSON.stringify(option)"
        :value="option"
      >
        {{ option.name ? option.name : option }}
      </option>
    </b-select>
    <b-dropdown v-else-if="field && field.type === 'textArea'" class="textarea-field">
      <button slot="trigger" slot-scope="{ active }" class="button is-small">
        {{ value ? value.substring(0, 40) : 'no value' }}
        <i :class="active ? 'mdi mdi-menu-up' : 'mdi mdi-menu-down'" />
      </button>
      <b-input
        :size="size"
        :value="value"
        class="row-value-input"
        type="textarea"
        @keyup.enter="editRow(index)"
        @input="$emit('input', $event)"
      />
    </b-dropdown>
    <b-input
      v-else
      :size="size"
      :value="value"
      class="row-value-input"
      type="text"
      @keyup.enter="editRow(index)"
      @input="$emit('input', $event)"
    />
  </span>
</template>
<script type="text/javascript">
import Checklist from '@/components/ui/Checklist';
import ClickOutside from 'vue-click-outside';

export default {
  components: {
    Checklist,
  },
  directives: {
    ClickOutside
  },
  props: {
    field: {
      type: Object,
      required: true,
    },
    value: {
      type: [String, Boolean, Object, Number, Array],
      default: undefined,
    },
    size: {
      type: String,
      default: undefined,
    },
    handleClickOutside: {
      type: Boolean,
      default: false,
    }
  },
  methods: {
    ClickOutside () {
      if (this.handleClickOutside) {
        console.log('ClickOutside');
        this.$emit('keyUpEnter');
      }
    },
    inputOnSelect(value) {
      this.$emit('input', value.id ? value.id : value);
    }
  }
};
</script>
<style scoped>
.minifield {
  min-width: 100px;
  display: inline-block;
}
.textarea-field >>> .dropdown-content, .textarea-field >>> .dropdown-content .control {
  padding: 0;
}
.textarea-field >>> .dropdown-menu {
  min-width: 0;
}
.textarea-field >>> textarea {
  min-width: 250px;
  min-height: 200px;
}
</style>
