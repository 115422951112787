<template>
  <span class="edit-panel-button lock-button panel-title mini-button" style="padding-left: 8px;padding-right: 0px; margin-top: -1px; margin-bottom: 12px">
    <a class="" @click="$emit('input', !value)" :style="`margin-top: -10px; ${value === false ? 'color: #f14668;': ''}`">
      <i :class="`mdi mdi-24px mdi-lock${value === false ? '-open': ''}`"/>&nbsp;
    </a>
    <b-switch :value="value" size="is-small" passive-type="is-danger" type="is-primary" style="padding-top: 10px;" @input="$emit('input', $event)"/>
  </span>
</template>
<script>
export default {
  props: {
    value: {
      type: Boolean,
      required: true
    }
  }
};
</script>
