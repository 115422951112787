<template>
  <div class="secondary-action-menu">
    <b-dropdown
      v-if="selection.length"
      position="is-top-left"
      aria-role="list">
      <template #trigger>
        <a class="button dropdown-button">
          <i class="mdi mdi-dots-vertical" />
        </a>
      </template>
      <b-dropdown-item :disabled="!selection || selection.length !== 1" aria-role="listitem" @click="duplicate">
        <span>Dupliquer l'élément sélectionné</span>
      </b-dropdown-item>
      <b-dropdown-item disabled aria-role="listitem" @click="openBatchEditWindow">
        <span>Edition en masse</span>
      </b-dropdown-item>
    </b-dropdown>
  </div>
</template>
<script>
import { mapState } from 'vuex';
import deepClone from '@/core/utils/deepClone';

export default {
  name: 'SecondaryActionButton',
  props: {
    schema: {
      type: Object,
      default: undefined,
    },
  },
  computed: {
    ...mapState({
      selection: (state) => state.config.selection,
    }),
  },
  methods: {
    openBatchEditWindow() {
      this.$store.commit('config/setBatchEditModalOpened', true);
    },
    duplicate() {
      const newElement = deepClone(this.selection[0]);
      if (this.schema) {
        if (this.schema && this.schema.dynamicValues) {
          const dynamicValuesKeys = Object.keys(this.schema.dynamicValues);
          for (let i = 0; i < dynamicValuesKeys.length; i++) {
            delete newElement[dynamicValuesKeys[i]];
          }
        }
      }
      this.$emit('duplicate', newElement);
    },
  },
};
</script>
<style scoped>
  .secondary-action-menu {
    position: relative;
    top: 0;
    left: -90px;
  }
  .dropdown-button {
    border-radius: 100%;
    width: 44px;
    height: 44px;
    box-shadow: 0 0 0 9px rgba(10, 10, 10, 0.1);
  }
</style>
