<template>
  <b-dropdown class="view-toolbar-item" aria-role="list" position="is-bottom-right" multiple @active-change="isPortalActive = false">
    <a class="dropdown-trigger top-view-button" slot="trigger">
      <i class="mdi mdi-database"/>Données
      <i class="mdi mdi-menu-down" />
    </a>
    <div class="edit-modal">
      <div v-show="isPortalActive" :style="isPortalActive ? 'max-height: 600px; overflow-y: auto;': 'height: 0px'">
        <portal-target name="portalComplexField" @change="isPortalActive = $event" :style="isPortalActive ? 'height:100%': ''"/>
      </div>
      <div v-show="!isPortalActive" style="padding: 20px;">
        <b-loading :is-full-page="false" v-model="saving" />
        <vue-form-generator
          :schema="schema"
          :model="editedModel"
          class="form"
          tag="div"
        />
        <div style="text-align: right">
          <a class="button is-primary" @click="apply">
            Appliquer
          </a>
        </div>
      </div>
    </div>
  </b-dropdown>
</template>
<script>
import deepClone from '@/core/utils/deepClone';

export default {
  props: {
    defaultSort: {
      type: [Object, String],
      default: undefined
    },
    panel: {
      type: Object,
      required: true,
    }
  },
  data () {
    return {
      saving: false,
      isPortalActive: false,
      schema: {
        fields: [{
          type: 'input',
          inputType: 'text',
          label: 'Collection',
          model: 'collection',
          disabled: (model) => {
            if (model && model._computedProperties && model._computedProperties.collection) {
              return true;
            }
            return false;
          },
          hint: 'Où sont stockées et récupérées les données de la vue',
        },{
          label: 'Type des éléments',
          model: 'schema',
          type: 'document',
          labelField: 'name',
          relation: {
            type: 'hasOne',
            collection: 'schemas',
          },
          bucket: 'schema',
          onlyFieldAsString: 'name',
          hint: "Nom du schéma, utilisé pour gérer le formulaire d'édition des enregistrements",
          canCreateNewDocument: true,
        }],
      },
      editedModel: undefined,
    };
  },
  watch: {
    'panel': {
      handler (v) {
        this.editedModel = deepClone(v);
      },
      deep: true,
      immediate: true,
    }
  },
  methods: {
    async apply () {
      this.saving = true;
      const res = await this.$store.dispatch('abstractElements/saveObject', {
        collection: 'panels',
        object: this.editedModel,
      });
      this.$store.commit('currentPanel/setCurrentPanel', res.payload.data.document);
      this.saving = false;
    },
  }
};
</script>
<style scoped>
.edit-modal {
  width: 520px;
}

.edit-modal >>> .field-array {
  display: block!important;
}
.edit-modal >>> .field-array > .field-wrap > div > .wrap > div > input {
  margin: 0;
  border-radius: 0 4px 4px 0;
}
.edit-modal >>> .autocomplete input {
  border-radius: 4px 0 0 4px;
}
.edit-modal >>> select {
  height: 40px;
  border-radius: 0;
}
.edit-modal >>> .hint {
  grid-column: 2;
}
.edit-modal >>> .card-header-title, .edit-modal >>> .card-header-icon {
  padding: 5px;
}
.edit-modal >>> .form-group {
  grid-template-columns: 180px calc(100% - 180px) !important;
}
.edit-modal >>> .complex-field-editor.document-field .field-header {
  display: none;
}

.edit-modal >>> .complex-field-editor.document-field .results {
  margin-top: 0;
}

</style>
