<template>
  <div class="query-group">
    <div class="query-group-title">
      <select v-if="!rootElement" :value="operator" @change="$emit('changeOperator', $event.target.value)">
        <option value="$and">
          {{ i18n.and }}
        </option>
        <option value="$or">
          {{ i18n.or }}
        </option>
      </select>
      <div v-else class="default-operator">
        {{ i18n.and }}
      </div>
      <button class="button is-small" @click="rules.push({ operator: '$eq' })">
        <i class="mdi mdi-plus" />
        {{ i18n.add_condition }}
      </button>
      <button class="button is-small" @click="rules.push({ operator: '$and', value: [] })">
        <i class="mdi mdi-plus" />
        {{ i18n.add_group }}
      </button>
      <a v-if="!rootElement" class="right-dropdown" @click="$emit('delete')">
        <i class="mdi mdi-close" />
      </a>
      <b-dropdown
        v-if="rootElement"
        ref="save-dropdown"
        class="save-dropdown"
        position="is-bottom-left"
        append-to-body
      >
        <a slot="trigger">
          <i class="mdi mdi-content-save" />
        </a>
        <div class="field save-field">
          <b-input v-model="saveName" type="text" size="is-small" />
          <button class="button is-small is-primary" @click="saveFilter">
            {{ i18n.save }}
          </button>
        </div>
      </b-dropdown>
      <b-dropdown
        v-if="rootElement"
        class="open-dropdown"
        position="is-bottom-left"
        append-to-body
      >
        <a slot="trigger">
          <i class="mdi mdi-folder-open" />
        </a>
        <span v-for="(f, name) in savedFilters" :key="name" class="dropdown-item">
          <a class="filter-label" @click="applyFilter(f)">
            {{ name }}
          </a>
          <a class="delete-button" @click="$store.commit('customFilters/deleteFilter', { name })">
            <i class="mdi mdi-delete" />
          </a>
        </span>
        <span v-if="hasSavedFilters" class="dropdown-item">
          {{ i18n.no_filters }}
        </span>
      </b-dropdown>
    </div>
    <div class="query-group-content">
      <div v-if="!rules || !rules.length" class="empty-group">
        {{ i18n.empty_group }}
      </div>
      <div v-for="(r, i) in rules" :key="i">
        <QueryBuilderGroup
          v-if="r.operator === '$and' || r.operator === '$or'"
          operator="$and"
          :schema="schema"
          :operators="operators"
          :rules="r.value"
          @changeOperator="changeOperator(r, $event)"
          @delete="rules.splice(i, 1)"
        />
        <QueryBuilderRule
          v-else
          :schema="schema"
          :rule="r"
          :operators="operators"
          @delete="rules.splice(i, 1)"
        />
      </div>
    </div>
  </div>
</template>
<script>
import { mapState } from 'vuex';
import QueryBuilderRule from '@/components/ui/QueryBuilder/QueryBuilderRule';
import i18n from 'i18n/components/ui/QueryBuilder/QueryBuilderGroup.json';

export default {
  name: 'QueryBuilderGroup',
  components: {
    QueryBuilderRule,
  },
  props: {
    rootElement: {
      type: Boolean,
      default: false,
    },
    rules: {
      type: Array,
      required: true,
    },
    schema: {
      type: Object,
      default: undefined,
    },
    operators: {
      type: Array,
      required: true,
    },
    operator: {
      type: String,
      default: '$and',
    },
  },
  data() {
    return {
      i18n,
      saveName: '',
    };
  },
  computed: {
    ...mapState({
      savedFilters: (state) => state.customFilters.filters,
    }),
    hasSavedFilters() {
      return !this.savedFilters || Object.keys(this.savedFilters).length === 0;
    },
  },
  methods: {
    applyFilter(f) {
      this.$emit('applyFilter', f);
    },
    changeOperator(rule, operator) {
      rule.operator = operator;
    },
    saveFilter() {
      this.$refs['save-dropdown'].toggle();
      this.$store.commit('customFilters/saveFilter', {
        name: this.saveName,
        filter: this.rules,
      });
      this.saveName = '';
    },
  },
};
</script>
<style scoped>
.query-group {
  margin-top: 8px;
  margin-bottom: 8px;
  padding-bottom: 8px;
  border: 1px solid #aaaaaa50;
  border-radius: 2px;
}
.query-group-title {
  width: 100%;
  margin-bottom: 8px;
  background: #aaaaaa50;
  padding: 4px;
}
.query-group-title >>> button, .query-group-title >>> select {
  background: #fff;
  border: 1px solid #aaa;
  height: 26px;
  border-radius: 2px;
}
.query-group-content {
  padding-right: 12px;
  padding-left: 12px;
}
.query-group-content > div {
  width: 100%;
}
.save-field {
  padding-left: 8px;
  padding-right: 8px;
}
.save-dropdown, .open-dropdown {
  float: right;
  padding-right: 10px;
}
.query-group >>> .save-dropdown .dropdown-menu, .query-group >>> .open-dropdown .dropdown-menu {
  width: 400px;
}

.empty-group {
  background: #ffffffF5;
  font-size: 80%;
  padding: 4px 10px;
  border-radius: 2px;
}

.dropdown-item {
  text-align: left;
  white-space: nowrap;
  width: 100%;
  display: inline-flex;
}
.filter-label {
  flex-grow: 1;
}
.right-dropdown {
  float: right;
  padding-right: 10px;
}
.default-operator {
  display: inline-block;
  font-size: 14px;
  padding-left: 4px;
  padding-right: 10px;
  padding-top: 2px;
  background: #fff;
  border: 1px solid #aaa;
  height: 26px;
  border-radius: 2px;
}
</style>
