<template>
  <div class="main-action-button">
    <b-tooltip position="is-left">
      <template slot="content">
        <span style="font-size: 125%; font-weight: bold">
          {{ label }}
        </span>
      </template>
      <template>
        <a class="main-action-button-link" @click="$emit('click')">
          <span>
            <slot />
          </span>
        </a>
      </template>
    </b-tooltip>
  </div>
</template>
<script>
export default {
  props: {
    label: {
      type: String,
      default: '',
    },
  },
};
</script>
<style scoped>
.main-action-button {
  z-index: 2;
}
.main-action-button-link {
  margin-left: 20px;
  display: inline-block;
  width: 68px;
  height: 68px;
  text-align: center;
  font-size: 50px;
  border-radius: 100%;
  color: white;
  background: var(--primary-color);
  position: relative;
  box-shadow: 0 0 0 9px rgba(10, 10, 10, 0.1);

}
.main-action-button-link:hover {
  background: var(--primary-color-darker);
}
.main-action-button-link > span {
  margin-top: -3px;
  display: block;
}
</style>
