<script>
import { mapState } from 'vuex';
import { debounce } from 'vue-debounce';
import templateVariables from '@/core/templateVariables';
import JSON5 from 'json5';
import { ToastProgrammatic as Toast } from 'buefy';
import redirect from '@/core/utils/redirect';

export default {
  props: {
    panelParams: {
      type: Object,
      default: () => ({}),
    },
    config: {
      type: Object,
      default: undefined,
    },
    sort: {
      type: [Object, String],
      default: undefined
    },
    backendPagination: {
      type: Boolean,
      default: false,
    }
  },
  data() {
    let activeFilter;
    if (this.config.filters) {
      if (this.$route.query.filter) {
        if (this.$route.query.filter === 'all') {
          activeFilter = undefined;
        } else {
          for (let i = 0; i < this.config.filters.length; i++) {
            if (this.config.filters[i].label === this.$route.query.filter) {
              activeFilter = this.config.filters[i];
            }
          }
        }
      } else {
        for (let i = 0; i < this.config.filters.length; i++) {
          if (this.config.filters[i].default) {
            activeFilter = this.config.filters[i];
          }
        }
      }
    }
    console.log('activeFilter from data', activeFilter, this.config.filters);
    return {
      isLoading: false,
      firstFetchDone: false,
      activeFilter,
      searchText: this.$route.query.searchText || undefined,
      searchQuery: undefined,
      elements: [],
      allElementsLocal: undefined,
      error: undefined,
      debouncedSearch: debounce(() => {
        redirect(this.$router, [{ query: { ...this.$route.query, page: 1 } }]);
        this.applyFilterAndSearch();
      }, 500),
      debouncedFetchObjects: debounce(() => {
        this.fetchObjects();
      }, 500),
    };
  },
  computed: {
    ...mapState({
      user: (state) => state.login.user,
    }),
    selection: {
      get() {
        return this.$store.state.config.selection;
      },
      set(v) {
        this.$store.commit('config/setSelection', v);
      },
    },
    queryTemplatesParams() {
      return templateVariables.get(this.$store.state, this.panelParams);
    },
    fuzzySearch: {
      get() {
        return this.$store.state.config.fuzzySearch;
      },
      set(v) {
        this.$store.commit('config/setFuzzySearch', v);
      },
    },
    storeModule() {
      return this.$store.state.experimentalFeatures.experimentalFeatures.backendPagination ? 'abstractElementsPaginated' : this.config.storeModule || 'abstractElements';
    },
    allElements: {
      get() {
        return this.allElementsLocal || this.$store.state[this.storeModule].objects[this.config.bucket || this.config.collection];
      },
      set(v) {
        this.allElementsLocal = v;
      },
    },
    schema: {
      get() {
        const schemaLookup = this.$store.state.abstractElements.objects.schemas.objects.filter(
          (s) => s.name === this.config.schema,
        );
        if (schemaLookup.length) {
          return schemaLookup[0];
        }
        return undefined;
      },
    },
    searchableKeys() {
      if (this.schema && this.schema.fields) {
        const k = this.schema.fields
          .filter((f) => ['input', 'text', 'select', 'number', 'textArea', 'customChecklist'].includes(f.type))
          .map((f) => f.model);
        k.push('_id');
        return k;
      }
      return [];
    },
  },
  watch: {
    "$parent.backendPagination": {
      handler () {
        this.fetchObjects();
      }
    },
    config: {
      handler(newV, oldV) {
        if(!oldV || newV._id !== oldV._id || newV.collection !== oldV.collection || newV.query !== oldV.query) {
          this.fetchObjects();
        }
      },
      deep: true,
      immediate: true
    },
    panelParams: {
      handler(v) {
        if (this.config.query) {
          this.fetchObjects();
        }
      },
      deep: true,
    },
    /*user: {
      handler(u, oldU) {
        console.log('username watch', u, oldU);
        if (u) {
          this.fetchObjects();
        }
      },
      immediate: true,
    },*/
    /*'allElements.objects': {
      handler() {
        this.doFilter();
      },
      deep: true,
    },*/
    activeFilter() { this.applyFilterAndSearch() },
    searchText () {
      this.doFilter();
    },
    searchQuery (newV) {
      if (newV && newV.length) {
        this.doFilter();
      }
    },
    sort: {
      handler: 'debouncedFetchObjects',
      deep: true
    }
  },
  mounted() {
    this.elements = this.allElements;
  },
  methods: {
    async fetchObjects() {
      this.isLoading = true;
      try {
        const res = await this.$store.dispatch(`${this.storeModule}/fetchObjects`, {
          collection: this.config.collection,
          destinationBucket: this.config.bucket,
          query: this.config.query,
          limit: this.config.limit,
          aggregate: this.config.aggregate,
          searchText: this.$route.query.searchText,
          sort: this.sort,
          page: this.$route.query.page ? parseInt(this.$route.query.page) : 1,
          perPage: this.$route.query.perPage,
          panelParams: this.panelParams,
          filter: this.activeFilter,
          search: {
            keys: this.searchableKeys || [],
            searchTerm: this.$route.query.searchText,
            fuzzySearch: this.fuzzySearch,
          }
        });
        this.firstFetchDone = true;
      } catch (e) {
        Toast.open({ message: 'Impossible de récupérer les données : ' + e, type: 'is-danger' });
        console.log(e);
      }
      this.isLoading = false;

      if ((this.activeFilter && this.activeFilter.query) || this.searchText) {
        this.applyFilterAndSearch();
      }
    },
    async applyFilterAndSearch() {
      if(this.firstFetchDone) {
        let { searchText } = this;
        let searchQuery;
        redirect(this.$router, [{ query: { ...this.$route.query, page: 1 } }]);

        try {
          if (this.searchText && (this.searchText.startsWith('{') || this.searchText.startsWith('['))) {
            searchQuery = JSON5.parse(searchText);
            if (!Array.isArray(searchQuery)) {
              searchQuery = [searchQuery];
            }
            searchText = undefined;
          }
          await this.$store.dispatch(`${this.storeModule}/filterObjects`, {
            bucket: this.config.bucket || this.config.collection,
            collection: this.config.collection,
            filter: this.activeFilter,
            query: searchQuery,
            limit: this.config.limit,
            aggregate: this.config.aggregate,
            panelParams: this.panelParams,
            search: {
              keys: this.searchableKeys || [],
              searchTerm: searchText,
              fuzzySearch: this.fuzzySearch,
            },
          });
        } catch (e) {
          console.error(e);
        }
      }
    },
    filterElements(filter) {
      this.activeFilter = filter;
    },
    searchElements(searchText, searchQuery) {
      this.searchText = searchText;
      this.searchQuery = searchQuery;
    },
    deleteElement($event) {
      this.$store.dispatch(this.deleteAction, {
        collection: this.config.collection,
        object: $event,
      });
    },
    goToRecordPage(record) {
      redirect(this.$router, [`/record/${this.config.collection}/${record._id}`]);
    },
    doFilter() {
      try {
        this.debouncedSearch();
      } catch (e) {
        console.error(e);
      }
    },
    toggleSelected(element) {
      const selection = [...this.selection];
      if (selection.includes(element)) {
        selection.splice(selection.indexOf(element), 1);
      } else {
        selection.push(element);
      }
      this.selection = selection;
    },
  },
};
</script>
