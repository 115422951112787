<template>
  <Modal :active="active" :width="1400" class="edit-modal export-modal" @close="$emit('input', false)">
    <template slot="header">
      <span class="modal-title">
        {{ i18n.export }}
      </span>
    </template>
    <div style="padding: 20px" v-if="allElements">
      <b-loading :is-full-page="true" :active="loading" :can-cancel="false" />
      <div v-if="!loading">
        <div>
          <h3 class="title is-3">
            {{ i18n.data_to_export }}
          </h3>
          <div class="control">
            <label class="radio">
              <input v-model="exportOnlySelection" type="radio" :value="false">
              Tout
            </label>
            <label class="radio">
              <input v-model="exportOnlySelection" type="radio" :value="true">
              Filtre actuel
            </label>
          </div>
          <h3 class="title is-3">
            {{ i18n.formats }}
          </h3>
          <div class="field" style="display: flex">
            <p class="control">
              <button class="button" @click="downloadXls()">
                <i class="mdi mdi-download" />
                <i class="mdi mdi-file-excel" />
                {{ i18n.excel_document }}
              </button>
            </p>
            <p class="control">
              <b-dropdown>
                <button slot="trigger" class="button">
                  <i class="mdi mdi-menu-down" />
                </button>
                <b-dropdown-item @click="download(`${panel.title}_data.json`, JSON.stringify(allElements))">
                  <i class="mdi mdi-code-braces" />
                  JSON
                </b-dropdown-item>
              </b-dropdown>
            </p>
          </div>
        </div>
        <b-collapse
          :open.sync="previewOpened"
          :class="`panel preview-panel ${previewFullscreen ? 'fullscreen' : ''} ${previewFixedColumns ? 'fixed-columns' : ''}`"
        >
          <div
            slot="trigger"
            class="panel-heading"
            role="button"
          >
            <i :class="`mdi mdi-menu-${previewOpened ? 'down' : 'right'}`" />
            <strong>{{ i18n.preview }}</strong>
            <a class="toggle-preview-mode-button" @click.stop="togglePreviewFullscreen">
              <i class="mdi mdi-fullscreen" />
            </a>
            <a class="toggle-preview-mode-button" @click.stop="togglePreviewFixedColumns">
              <i class="mdi mdi-arrow-expand-horizontal" />
            </a>
          </div>
          <div v-if="schema" class="panel-block">
            <table v-if="exportOnlySelection" ref="export-table-preview" class="export-table-preview">
              <tr>
                <th v-for="p in schema.fields" :key="p.model">
                  {{ p.label }}
                </th>
              </tr>
              <tr v-for="obj in allElements.filteredObjects" :key="obj._id">
                <td v-for="field in schema.fields" :key="field.model">
                  <v-runtime-template
                    v-if="field.type === 'textArea' && typeof displayValue(obj, field) === 'string'"
                    :template="'<span class=\'value\'>'+displayValue(obj, field).split('\n').join('<br/>') + '</span>'"
                  />
                  <template v-else>
                    <span class="value">
                      {{ displayValue(obj, field) }}
                    </span>
                  </template>
                </td>
              </tr>
            </table>
            <table v-else ref="export-table-preview" class="export-table-preview">
              <tr>
                <th v-for="p in schema.fields" :key="p.model">
                  {{ p.label }}
                </th>
              </tr>
              <tr v-for="obj in allElements.objects" :key="obj._id">
                <td v-for="field in schema.fields" :key="field.model">
                  <v-runtime-template
                    v-if="field.type === 'textArea' && typeof displayValue(obj, field) === 'string'"
                    :template="'<span class=\'value\'>'+displayValue(obj, field).split('\n').join('<br/>') + '</span>'"
                  />
                  <template v-else>
                    <span class="value">
                      {{ displayValue(obj, field) }}
                    </span>
                  </template>
                </td>
              </tr>
            </table>
          </div>
        </b-collapse>
      </div>
    </div>
  </Modal>
</template>
<script>
import VRuntimeTemplate from 'v-runtime-template';
import Modal from '@/components/modals/Modal';
import exportFunctions from '@/core/exportFunctions';
import i18n from 'i18n/components/modals/ExportModal.json';

export default {
  components: {
    Modal,
    VRuntimeTemplate,
  },
  props: {
    value: {
      type: Boolean,
      default: true,
    },
    panel: {
      type: Object,
      required: true,
    },
    schema: {
      type: [Object],
      default: undefined,
    },
  },
  data() {
    return {
      i18n,
      exportFunctions,
      exportOnlySelection: true,
      previewFullscreen: false,
      previewFixedColumns: false,
      previewOpened: false,
      active: this.value,
      loading: false,
    };
  },
  watch: {
    active: {
      handler (v) {
        if (v) {
          this.loading = true;
          this.$nextTick(async () => {
            await this.$store.dispatch(`${this.storeModule}/fetchAllObjects`, {
              collection: this.panel.collection,
              destinationBucket: this.panel.bucket
            });
            this.loading = false;
          });
        }
      },
      immediate: true,
    },
    value(v) {
      this.active = v;
    },
    previewOpened(v) {
      if (!v) {
        this.previewFullscreen = false;
      }
    },
  },
  computed: {
    storeModule() {
      return this.$store.state.experimentalFeatures.experimentalFeatures.backendPagination ? 'abstractElementsPaginated' : this.panel.storeModule || 'abstractElements';
    },
    allElements: {
      get() {
        return this.$store.state[this.storeModule].objects[this.panel.bucket || this.panel.collection];
      }
    }
  },
  methods: {
    togglePreviewFullscreen() {
      this.previewFullscreen = !this.previewFullscreen;
      this.previewOpened = true;
    },
    togglePreviewFixedColumns() {
      this.previewFixedColumns = !this.previewFixedColumns;
    },
    download(filename, text) {
      const element = document.createElement('a');
      element.setAttribute('href', `data:text/plain;charset=utf-8,${encodeURIComponent(text)}`);
      element.setAttribute('download', filename);

      element.style.display = 'none';
      document.body.appendChild(element);

      element.click();

      document.body.removeChild(element);
    },
    async downloadXls() {
      //const XLSX = await import(/* webpackChunkName: "xlsx" */ 'xlsx');
      await this.$loadScript('https://cdnjs.cloudflare.com/ajax/libs/xlsx/0.15.6/xlsx.full.min.js');
      const wb = XLSX.utils.book_new();
      wb.SheetNames.push('Data');
      const ws2 = XLSX.utils.table_to_sheet(this.$refs['export-table-preview'], { raw: true, type: 'string' });
      wb.Sheets.Data = ws2;
      XLSX.writeFile(wb, `${this.panel.title}.xlsx`);
    },
    displayValue(obj, field) {
      let res = obj[field.model];
      if (field.exportFunction) {
        let functionName = field.exportFunction;
        let functionParams = [];
        if (field.exportFunction.includes(':')) {
          functionName = field.exportFunction.split(':');
          functionParams = functionName[1].split(',');
          functionName = functionName[0];
        }
        if (exportFunctions[functionName]) {
          res = exportFunctions[functionName](obj[field.model], {
            params: functionParams,
            field,
          });
        }
      }
      if (Array.isArray(res)) {
        return res.join(',');
      }
      return res;
    },
  },
};
</script>
<style scoped>
.panel-block {
  overflow: auto;
  font-size: 75%;
  height: calc(85vh - 197px);
}
.panel {
  margin-top: 30px;
}
.panel-block {
  position: relative;
  height: 400px;
}
.export-table-preview {
  table-layout:fixed;
  position: absolute;
  top: 0;
  left: 0;
}
.export-modal .export-table-preview th {
  background: #ccc;
  padding: 4px;
}
.export-table-preview td, .export-modal .export-table-preview th {
  border: 1px solid #ccc;
  width: 200px;
  height: 30px;
  max-height: 30px;
  overflow: hidden;
  position: relative;
  white-space: nowrap;
}
.toggle-preview-mode-button {
  float: right;
  padding-left: 20px;
}
.preview-panel.fullscreen {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 100vh;
  margin-top: 0;
  background: white;
}
.preview-panel.fullscreen .panel-block {
  position: fixed;
  top: 55px;
  left: 0;
  right: 0;
  height: calc(100vh - 55px);
  margin-top: 0;
  background: white;
}

.preview-panel.fixed-columns >>> .value, .preview-panel.fixed-columns th {
  width: 60px;
}

.preview-panel >>> .value {
  height: 30px;
  display: inline-block;
  overflow: hidden;
  padding: 4px;
}
</style>
