<template>
  <b-dropdown class="import-type-badge" append-to-body aria-role="menu" trap-focus >
    <span slot="trigger" :class="`tag ${value ? value.tagType : 'is-success'}`" style="width: 80px; margin-top: 2px;">
      {{ i18n[value ? value.insertType : 'add'] }}
      <i class="mdi mdi-menu-down" />
    </span>
    <div style="width: 800px;">
      <span v-if="value && value.comparationResult">
        <table v-if="value.comparationResult.length" class="table is-striped" style="width: 800px">
          <tr>
            <th>{{ i18n.key }}</th>
            <th>{{ i18n.old }}</th>
            <th>{{ i18n.new }}</th>
          </tr>
          <tr v-for="k in value.comparationResult" :key="k.key">
            <td>{{k.key}}</td>
            <td>{{k.oldV}}</td>
            <td>{{k.newV}}</td>
          </tr>
        </table>
        <span v-else style="font-size: 80%; padding: 6px;">
          {{ i18n.record_is_unchanged }}
        </span>
      </span>
      <span v-else style="font-size: 80%; padding: 6px;">
        {{ i18n.this_row_will_be_inserted_as_a_new_record }}
      </span>
      <button @click="log">
        log
      </button>
    </div>
  </b-dropdown>
</template>
<script type="text/javascript">
import i18n from 'i18n/components/modals/ImportModal/ImportTypeBadge.json';

function onlyUnique(value, index, self) {
  return self.indexOf(value) === index;
}

export default {
  props: {
    value: {
      type: Object,
      default: undefined,
    },
    panel: {
      type: Object,
      required: true,
    },
    schema: {
      type: Object,
      required: true,
    },
    row: {
      type: Object,
      required: true,
    },
    previouslyInsertedRows: {
      type: Object,
      default: () => ({}),
    },
    importedFields: {
      type: Array,
      default: () => [],
    },
    overwrittenFields: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      i18n,
      insertType: undefined,
      comparationResult: undefined,
      tagType: undefined,
      filteredRow: undefined,
      changes: [],
    };
  },
  computed: {
    previouslyInsertedRow() {
      if (this.panel && this.upsertKey && this.previouslyInsertedRows && this.row[this.upsertKey] !== undefined) {
        return this.previouslyInsertedRows[this.row[this.upsertKey]];
      }
      return undefined;
    },
    upsertKey() {
      if (this.schema && this.schema.fields) {
        for (const f of this.schema.fields) {
          if (f.isIdentifier) {
            return f.model;
          }
        }
      }
      return undefined;
    },

  },
  watch: {
    row: {
      handler(r) {
        if (r) {
          this.filteredRow = this.extractRowForTesting(r);
        } else {
          this.filteredRow = undefined;
        }
      },
      deep: true,
      immediate: true,
    },
    previouslyInsertedRow: {
      handler(r) {
        if (r) {
          this.filteredPreviouslyInsertedRow = this.extractRowForTesting(r);
        } else {
          this.filteredPreviouslyInsertedRow = undefined;
        }
      },
      deep: true,
      immediate: true,
    },
    filteredRow: {
      handler: 'setInsertType',
      immediate: true,
      deep: true,
    },
    filteredPreviouslyInsertedRow: {
      handler: 'setInsertType',
      immediate: true,
      deep: true,
    },
  },
  methods: {
    log() {
      console.log(this.filteredPreviouslyInsertedRow, this.filteredRow);
    },
    compareObject(oldV, newV) {
      if (oldV === undefined) {
        return undefined;
      }
      const res = [];
      const keys = Object.keys(oldV).concat(Object.keys(newV)).filter(onlyUnique);
      for (const key of keys) {
        let testedOldKey = oldV[key];
        let testedNewKey = newV[key];
        if (typeof testedOldKey === 'string') {
          testedOldKey = testedOldKey.replace(/\s/g, '').replace(/(\r\n|\n|\r)/g, '');
        }
        if (typeof testedNewKey === 'string') {
          testedNewKey = testedNewKey.replace(/\s/g, '').replace(/(\r\n|\n|\r)/g, '');
        }
        if (JSON.stringify(testedOldKey) !== JSON.stringify(testedNewKey)) {
          res.push({ key, oldV: oldV[key], newV: newV[key] });
        }
      }
      return res;
    },
    setInsertType() {
      if (this.filteredRow && this.filteredPreviouslyInsertedRow) {
        const comparationResult = this.compareObject(this.filteredPreviouslyInsertedRow, this.filteredRow);
        if (comparationResult.length) {
          this.row.__insertType = 'edit';
          this.$emit('input', {
            insertType: 'edit',
            tagType: 'is-warning',
            comparationResult,
          });
        } else {
          this.row.__insertType = 'unchanged';
          this.$emit('input', {
            insertType: 'unchanged',
            comparationResult,
          });
        }
      } else {
        this.row.__insertType = 'add';
        this.$emit('input', {
          tagType: 'is-success',
          insertType: 'add',
          comparationResult: undefined,
        });
      }
    },
    extractRowForTesting(row) {
      const res = {};
      const handleField = (f) => {
        const key = f.model;
        let value = row[key];
        if (typeof value === 'string') {
          value = value.trim();
        }
        if (value === null) {
          value = undefined;
        }
        if (f.type === 'input' && f.inputType === 'text' && value) {
          value = `${value}`;
        }
        if (f.relation && !f.onlyFieldAsString && typeof value === 'object') {
          if (value._id && value._id !== '') {
            res[key] = value._id;
          } else {
            res[key] = undefined;
          }
        } else if (f.type === 'textArea' && value !== '') {
          res[key] = value;
        } else if (f.inputType === 'number') {
          res[key] = parseInt(value);
        } else if (value === '') {
          res[key] = undefined;
        } else {
          res[key] = value;
        }
      };
      this.importedFields.map(handleField);
      this.overwrittenFields.map(handleField);
      return res;
    },
  },
};
</script>
<style scoped>
table {
  font-size: 75%;
}
td {
  max-width: 200px;
  overflow-x: auto;
}
</style>
<style>
.import-type-badge .dropdown-content {
  max-height: 400px;
  overflow-y: auto;
}
.tag {
  cursor: pointer;
}
</style>
