<template>
  <div class="edit-modal">
    <div :style="isPortalActive ? 'max-height: 600px; overflow-y: auto;': 'height: 0px'">
    <portal-target v-show="isPortalActive" name="portalComplexField" @change="isPortalActive = $event" :style="isPortalActive ? 'height:100%': ''"/>
  </div>
  <div v-show="!isPortalActive" style="padding: 20px;">
      <b-loading :is-full-page="false" v-model="saving" />
      <vue-form-generator
        :schema="schema"
        :model="editedModel"
        class="form"
        tag="div"
      />
      <div style="text-align: right">
        <a class="button is-primary" @click="apply">
          Appliquer
        </a>
      </div>
    </div>
  </div>
</template>
<script>
import deepClone from '@/core/utils/deepClone';

export default {
  props: {
    defaultSort: {
      type: [Object, String],
      default: undefined
    }
  },
  data () {
    return {
      saving: false,
      isPortalActive: false,
      schema: {
        fields: [{
          type: 'category',
          label: 'Dossier',
          model: 'category',
        }, {
          label: 'Groupe',
          model: 'group',
          type: 'text',
      //}, {
      //    type: 'text',
      //    label: 'Environnement',
      //    model: 'workgroup',
        }]
      },
      editedModel: undefined,
    };
  },
  watch: {
    '$store.state.currentPanel.currentPanel': {
      handler (v) {
        this.editedModel = deepClone(v);
      },
      deep: true,
      immediate: true,
    }
  },
  methods: {
    async apply () {
      this.saving = true;
      const res = await this.$store.dispatch('abstractElements/saveObject', {
        collection: 'panels',
        object: this.editedModel,
      });
      this.$store.commit('currentPanel/setCurrentPanel', res.payload.data.document);
      this.saving = false;
    },
  }
};
</script>
<style scoped>
.edit-modal {
  width: 500px;
}

.edit-modal >>> .field-array {
  display: block!important;
}
.edit-modal >>> .field-array > .field-wrap > div > .wrap > div > input {
  margin: 0;
  border-radius: 0 4px 4px 0;
}
.edit-modal >>> .autocomplete input {
  border-radius: 4px 0 0 4px;
}
.edit-modal >>> select {
  height: 40px;
  border-radius: 0;
}
.edit-modal >>> .hint {
  grid-column: 2;
}
.edit-modal >>> .card-header-title, .edit-modal >>> .card-header-icon {
  padding: 5px;
}
.edit-modal >>> .form-group {
  grid-template-columns: 160px calc(100% - 160px) !important;
}
.edit-modal >>> .complex-field-editor.document-field .field-header {
  display: none;
}

.edit-modal >>> .complex-field-editor.document-field .results {
  margin-top: 0;
}

</style>
