<template>
  <span class="cell-editor">
    <Minifield v-model="valueCopy" :field="colSchema" size="is-small" @keyUpEnter="changeCellValue" handle-click-outside style="position: absolute; top: -1px; left: -2px; right: -4px; bottom: -9px"/>
  </span>
</template>
<script>
import Minifield from '@/components/Minifield';
import deepClone from '@/core/utils/deepClone';

export default {
  props: {
    value: {
      required: true
    },
    colSchema: {
      type: Object,
      default: undefined,
    }
  },
  components: {
    Minifield,
  },
  data() {
    setTimeout(() => this.firstClickGuardPassed = true, 1000);
    return {
      firstClickGuardPassed: false,
      valueCopy: undefined
    };
  },
  watch: {
    value: {
      handler(v) {
        try {
          this.valueCopy = deepClone(v);
        } catch(e) {
          this.valueCopy = undefined;
        }
      },
      immediate: true
    }
  },
  methods: {
    changeCellValue() {
      if (this.firstClickGuardPassed) {
        console.log('changeCellValue in celleditor', this.valueCopy);
        this.$emit('input', this.valueCopy);
      }
    }
  }
};
</script>
<style scoped>
.cell-editor {
  position: relative;
}
.cell-editor >>> .minifield {
  border: 2px solid #ffa00a;
}
.cell-editor >>> .minifield input {
  outline: none;
  box-shadow: none;
}
</style>
