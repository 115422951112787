<template>
  <div class="panel-title-bar-wrapper">
    <div :class="`panel-title-bar ${isMobile ? 'mobile' : 'desktop'}`">
      <div to="top-view-buttons" class="buttons view-menu toolbar" v-if="!hideEditButton">
        <div style="display: flex; width: 100%;">
          <div class="view-title-wrapper">
            <span v-if="PermissionManager.can(actions.can_edit_panels) && !editLocked">
              <IconSelector v-if="panel" class="view-icon-selector" :value="panel.icon || 'table'" @input="saveViewIcon"/>
              <span v-if="isLoading">
                <i>Chargement...</i>
              </span>
              <ViewTitle v-else :panel="panel" />
            </span>
            <span v-else class="panel-title">
              <span v-if="isLoading">
                <i>Chargement...</i>
              </span>
              <span v-else>
                <i v-if="panel && panel.icon" :class="`mdi mdi-${panel.icon}`" />
                <i v-else class="mdi mdi-table" />
                {{ panel ? panel.title || '&lt;Untitled panel&gt;' : '&lt;Untitled panel&gt;' }}
              </span>
            </span>
          </div>

          <PanelEditionDropdowns :panel="panel" />
          <span style="flex-grow: 1" />
          <ViewUnlockEditButton v-model="editLocked" v-if="PermissionManager.can(actions.can_edit_panels)" />
          <LockHistory
            :lock-name="`see/view/${panel._id}`"
          />
          <CollaboratingUsersPreview
            v-if="VUE_APP_HAS_COLLABORATING_MODE && panel"
            :lock-name="`see/view/${panel._id}`"
            style="margin-top: 2px;"
          />
          <b-dropdown class="view-toolbar-item" v-if="!isMobile" aria-role="list" position="is-bottom-left" append-to-body>
            <a slot="trigger" class="mini-button additionnal-view-menu-trigger" style="color: black">
              <i class="mdi mdi-dots-vertical mdi-18px" />
            </a>
            <b-dropdown-item
              v-if="PermissionManager.can(actions.can_export_view)"
              aria-role="listitem"
              class="export-panel-button"
              @click="download(`${panel.title}.json`, JSON.stringify(panel))"
            >
              <i class="mdi mdi-download" /> {{ i18n.export_panel }}
            </b-dropdown-item>
            <b-dropdown-item
              aria-role="listitem"
              class="export-data-button"
              @click="exportModalOpened = true"
            >
              <i class="mdi mdi-download" /> {{ i18n.export_data }}
            </b-dropdown-item>
            <b-dropdown-item
              v-if="PermissionManager.can(actions.can_import_data) && !isImportDisabled"
              aria-role="listitem"
              class="import-data-button"
              @click="importModalOpened = true"
            >
              <i class="mdi mdi-upload" /> {{ i18n.import_data }}
            </b-dropdown-item>
            <b-dropdown-item
              v-if="PermissionManager.can(actions.table_view_mode)"
              aria-role="listitem"
              :class="`table-view ${tableViewMode ? 'is-active': ''}`"
              @click="$emit('setTableViewMode', !tableViewMode)"
            >
              <i class="mdi mdi-table" /> {{ i18n.table_view_mode }}
            </b-dropdown-item>
             <b-dropdown-item class="is-danger" v-if="PermissionManager.can(actions.can_edit_panels)" @click="showDeleteConfirmModal = true">
                <i class="mdi mdi-delete"/> Supprimer la vue
              </b-dropdown-item>
          </b-dropdown>
        </div>
      </div>
      <div class="search-bar-wrapper" v-if="panel.collection">
        <portal-target name="titlebar-block-buttons" />
        <PanelFilters
          class=""
          style="min-width: 200px; height: 40px; padding: 0;"
          :filters="panel.filters"
          :active-filter="activeFilter"
          @filterElements="filterElements($event)"
        />
        <SearchBar
          :panel="panel"
          :value="searchText"
          :schema="getSchemaByName(panel.schema)"
          :compiled-advanced-rules="compiledAdvancedRules"
          @advancedSearch="advancedSearch"
          @input="searchText = $event"
        />
        <span class="panel-filters">
          <b-dropdown class="view-toolbar-item" aria-role="list" position="is-bottom-left" multiple
            style="width: 78px;">
            <a class="dropdown-trigger" slot="trigger">
              <i class="mdi mdi-sort-ascending" />
              <span>Tri</span>
              <i class="mdi mdi-menu-down" />
            </a>
            <MongoSortInput v-model="sort" :schemaName="panel.schema" :default-sort="panel.sort"/>
          </b-dropdown>
        </span>
      </div>
      <portal to="main-action-button">
        <SecondaryActionButton
          :schema="getSchemaByName(panel.schema)"
          @duplicate="addElement($event)"
        />
        <MainActionButton
          :label="i18n.new_record"
          @click="addElement"
        >
          <i class="mdi mdi-plus" />
        </MainActionButton>
      </portal>
      <portal to="paneltitlebar-modals">
        <Modal class="delete-view-modal" :active="!!showDeleteConfirmModal" :width="600" @close="showDeleteConfirmModal = false">
          Voulez-vous vraiment supprimer la vue "{{panel.title}}" ?
          <div class="field has-addons" style="float: right; margin-top: 20px;">
            <p class="control">
              <button class="button is-danger" @click="deletePanel">
                Oui
              </button>
            </p>
            <p class="control">
              <button class="button" @click="showDeleteConfirmModal = false">
                Non
              </button>
            </p>
          </div>
        </Modal>
        <ExportModal
          v-model="exportModalOpened"
          :panel="panel"
          :all-elements="allElements"
          :schema="getSchemaByName(panel.schema, panel, 'test')"
        />
        <ImportModal
          v-model="importModalOpened"
          :panel="panel"
          :schema="getSchemaByName(panel.schema)"
        />
        <BatchEditModal
          :schema="getSchemaByName(panel.schema, panel, 'test')"
          :panel="panel"
        />
      </portal>
      <slot />
      <portal-target name="template-editor-content" />
    </div>
  </div>
</template>
<script>
import { isMobile } from 'mobile-device-detect';
import { mapState } from 'vuex';
import Vue from 'vue';
import ImportModal from '@/components/modals/ImportModal';
import ExportModal from '@/components/modals/ExportModal';
import BatchEditModal from '@/components/modals/BatchEditModal';
import SearchBar from '@/components/SearchBar';
import LockHistory from '@/components/LockHistory';
import PermissionManager from '@/permissions/PermissionManager';
import actions from '@/permissions/actions';
import DocumentDropdown from '@/components/DocumentDropdown';
import PanelFilters from '@/components/PanelFilters';
import MainActionButton from '@/components/MainActionButton';
import SecondaryActionButton from '@/components/SecondaryActionButton';
import PanelEditionDropdowns from '@/components/PanelEditionDropdowns';
import MongoSortInput from '@/components/ui/MongoSortInput';
import i18n from 'i18n/components/PanelTitleBar.json';
import Modal from '@/components/modals/Modal';
import IconSelector from '@/components/ui/IconSelector';
import ViewTitle from '@/components/ViewTitle';
import ViewUnlockEditButton from '@/components/ui/ViewUnlockEditButton';
import CollaboratingUsersPreview from '@/components/CollaboratingUsersPreview';
import redirect from '@/core/utils/redirect';

Vue.component('DocumentDropdown', DocumentDropdown);

export default {
  name: 'PanelTitleBar',
  components: {
    LockHistory,
    CollaboratingUsersPreview,
    ImportModal,
    ExportModal,
    BatchEditModal,
    PanelFilters,
    MainActionButton,
    SecondaryActionButton,
    SearchBar,
    MongoSortInput,
    Modal,
    IconSelector,
    ViewTitle,
    ViewUnlockEditButton,
    PanelEditionDropdowns,
  },

  props: {
    panel: {
      type: Object,
      required: true,
    },
    panelParams: {
      type: Object,
      default: () => ({}),
    },
    tableViewMode: {
      type: Boolean,
      default: false,
    },
    hideEditButton: {
      type: Boolean,
      default: false,
    }
  },

  data() {
    let activeFilter = {};
    if (this.panel.filters && this.$route.query.filter !== 'all') {
      if (!this.$route.query.filter) {
        activeFilter = this.panel.filters.filter((f) => f.default)[0] || {};
      } else {
        activeFilter = this.panel.filters.filter((f) => f.label === this.$route.query.filter)[0] || {};
      }
    }
    if (this.$route.query.searchText) {
      this.$parent.searchElements(this.$route.query.searchText, []);
    }
    return {
      i18n,
      isMobile,
      VUE_APP_HAS_COLLABORATING_MODE: process.env.VUE_APP_HAS_COLLABORATING_MODE,
      importModalOpened: false,
      exportModalOpened: false,
      actions,
      PermissionManager,
      showDeleteConfirmModal: false,
      compiledAdvancedRules: [],
      advancedRules: [],
      searchText: this.$route.query.searchText || '',
      activeFilter,
    };
  },
  computed: {
    ...mapState({
      config: (state) => state.config.config,
      sort: (state) => state.currentPanel.currentPanelSort,
      loadedPanel: (state) => state.currentPanel.currentPanel,
      editLocked: (state) => state.currentPanel.editLocked,
    }),
    editLocked: {
      get() {
        return this.$store.state.currentPanel.editLocked;
      },
      set(v) {
        this.$store.commit('currentPanel/setEditLocked', v);
      }
    },
    storeModule() {
      return this.$store.state.experimentalFeatures.experimentalFeatures.backendPagination ? 'abstractElementsPaginated' : this.panel.storeModule || 'abstractElements';
    },
    isImportDisabled() {
      if (this.panel && this.panel.importDisabled && this.panel.importDisabled === true) {
        return true;
      }
      return false;
    },
    allElements: {
      get() {
        return this.$store.state[this.storeModule].objects[this.panel.bucket || this.panel.collection];
      },
      set() {},
    },
    fuzzySearch: {
      get() {
        return this.$store.state.config.fuzzySearch;
      },
      set(v) {
        this.$store.commit('config/setFuzzySearch', v);
      },
    },
  },
  watch: {
    panel () {
      this.showDeleteConfirmModal = false;
    },
    '$route.query.searchText': function (searchText) {
      this.$parent.searchElements(searchText, this.compiledAdvancedRules);
      this.searchText = searchText;
    },
    searchText(searchText) {
      if (this.$route.query.searchText !== searchText) {
        redirect(this.$router, [{ query: { ...this.$route.query, searchText } }]);
      }
    },
    compiledAdvancedRules(v) {
      this.$parent.searchElements(this.searchText, this.compiledAdvancedRules);
    },
  },
  methods: {
    async saveViewIcon (icon) {
      const res = await this.$store.dispatch('abstractElements/saveObject', {
        collection: 'panels',
        object: { ...this.loadedPanel, icon },
      });
      this.loadedPanel.icon = icon;
    },

    async deletePanel() {
      //TODO handle loading
      await this.$store.dispatch('abstractElements/deleteObject', {
        collection: 'panels',
        object: this.panel,
      });
      this.$store.commit('panels/setEditedPanel', undefined);
      this.$store.commit('panels/setEditedElementPanel', undefined);
      this.$store.commit('panels/setEditingView', false);
      redirect(this.$router, ['/']);
    },
    advancedSearch(searchQuery) {
      this.compiledAdvancedRules = searchQuery;
    },
    download(filename, text) {
      const element = document.createElement('a');
      element.setAttribute('href', `data:text/plain;charset=utf-8,${encodeURIComponent(text)}`);
      element.setAttribute('download', filename);

      element.style.display = 'none';
      document.body.appendChild(element);

      element.click();

      document.body.removeChild(element);
    },
    addElement(defaultSpecifiedValue) {
      const schema = this.getSchemaByName(this.panel.schema);
      let defaultValue = defaultSpecifiedValue || {};
      if (this.$store.state.login.currentWorkgroup) {
        defaultValue.workgroup = this.$store.state.login.currentWorkgroup;
      }
      if (schema && schema.defaultValue) {
        defaultValue = {
          ...defaultValue,
          ...schema.defaultValue,
        };
      }
      if (this.panel.defaultValue) {
        defaultValue = {
          ...defaultValue,
          ...this.panel.defaultValue,
        };
      }
      this.$store.commit('panels/setAddedElement', { ...defaultValue });
      this.$store.commit('panels/setAddedElementPanel', this.panel);
    },
    getSchemaByName(schemaName) {
      const schemas = this.$store.state.abstractElements.objects.schemas.objects;
      for (let i = 0; i < schemas.length; i++) {
        if (schemas[i].name === schemaName) {
          return schemas[i];
        }
      }
      return undefined;
    },
    filterElements(filter) {
      this.activeFilter = filter;
      this.$parent.filterElements(filter);
    },
    blurSearch() {
      this.panelSearch = '';
      document.activeElement.blur();
    },
  },
};
</script>
<style scoped>
.panel-title-bar-wrapper {
  height: 100px;
  position: relative;
}
.panel-title-bar.mobile {
  width: 100%;
  overflow-x: auto;
  z-index: 10;
}
.panel-title-bar.desktop {
  position: sticky;
  top: 1px;
  left: 1px;
  z-index: 1000;
}
.panel-title-bar.mobile h1 {
  font-size: 16px;
  z-index: 10;
  padding-top: 10px;
  padding-left: 52px;
  margin-bottom: 10px;
}
.buttons {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  height: 38px;
}
.panel-title-bar.mobile .buttons {
  position: static;
  height: 20px;
}

.panel-title-bar.mobile >>> .filters {
  padding-left: 48px;
}
.panel-title-bar.desktop .settings-button, .panel-title-bar.desktop .menu-button {
  visibility: hidden;
}

.toolbar {
  background: #FFFFFFCC;
  display: flex;
}
.button-label {
  display: inline-block;
  padding: 8px 0;
}
.edit-panel-button {
  color: black;
  font-weight: bold;
  transform: font-weight 0.2s;
}
.lock-button:hover {
  background: #eaeaea;
}
.view-title {
  padding: 8px;
}
.panel-title {
  text-overflow: ellipsis;
}

.view-icon-selector >>> .dropdown-trigger > .button {
  border: 0;
  font-size: 75%;
  padding: 4px 4px;
  margin-top: -3px;
}
.view-icon-selector >>> .dropdown-trigger > .button:hover {
  background: #ddd;
}

.view-icon-selector >>> .dropdown-trigger > .button .mdi-menu-down {
  display: none;
}
.view-title-wrapper {
  padding: 8px;
  color: #363636;
}
</style>
<style>
.search-bar-wrapper {
  display: flex;
  position: absolute;
  left: 20px;
  right: 20px;
  top: 50px;
}
.menu-hidden .search-bar-wrapper {
  left: -240px;
  right: 340px;
}
.search-bar-wrapper .view-toolbar-item {
  color: black;
  box-shadow: 0 2px 3px rgb(10 10 10 / 10%), 0 0 0 1px rgb(10 10 10 / 10%);
  background: white;
}
</style>
