export default {
  data() {
    return {
      paginationPageOld: 1,
      paginationPerPageOld: 10,
    };
  },
  computed: {
    paginationPage: {
      get () {
        return this.allElements ? this.allElements.page : 1;
      },
      set (v) {
        this.$store.dispatch(`${this.storeModule}/setPage`, {
          bucket: this.config.bucket || this.config.collection,
          page: v,
          panelParams: this.panelParams,
        });
      }
    },
    paginationPerPage: {
      get () {
        return this.allElements ? this.allElements.perPage : 10;
      },
      set (v) {
        this.$store.dispatch(`${this.storeModule}/setPerPage`, {
          bucket: this.config.bucket || this.config.collection,
          perPage: v,
          panelParams: this.panelParams,
        });
      }
    },
    paginationOffsetStart () {
      return (this.paginationPage - 1) * this.paginationPerPage;
    },
    paginatedFilteredElements () {
      return this.allElements.paginatedFilteredElements || [];
    },
    paginatedFilteredElementsTotal () {
      return this.allElements && this.allElements.filteredObjects ? this.allElements.filteredObjects.length : 0;
    },
  },
  methods: {
    onPerPageChange (perPage) {
      this.paginationPerPage = perPage;
    },
    onPageChange (page) {
      this.paginationPage = page;
    },
  },
};
