<template>
  <div class="search-bar-container" @mouseenter="hover = true" @mouseleave="hover = false">
    <div :class="`search-bar-container ${isCode ? 'is-code': ''} ${inputFocused ? 'input-focused' : ''}`">
      <span class="field panelTitleBarSearch" style="position: relative; display: flex;">
        <b-input
          :value="value"
          :placeholder="i18n.search"
          class="panelTitleBarSearch"
          type="search"
          :icon="isCode ? 'console-line': 'magnify'"
          @keydown.native="$parent.checkSearchShortcuts"
          @keydown.esc.native="blurSearch"
          @keydown.enter.native="$emit('input', $el.querySelector('.panelTitleBarSearch input').value)"
          @focus="inputFocus"
          @blur="inputBlur"
          @input="(value) => { if(autoSearch) $emit('input', value); }"
        />
        <b-dropdown aria-role="list" class="search-settings-button" position="is-bottom-left" :close-on-click="false">
          <template #trigger="{ active }">
            <a :class="hover ? 'trigger visible': 'trigger'">
              <i class="mdi mdi-wrench" />
              <i class="mdi mdi-menu-down" />
            </a>
          </template>
          <b-dropdown-item aria-role="listitem" :class="autoSearch ? 'is-active' : ''" @click="toggleAutoSearch">
            <i :class="`mdi mdi-checkbox-${autoSearch ? 'marked': 'blank'}`" />&nbsp; Lancement de la recherche à la saisie
          </b-dropdown-item>
        </b-dropdown>
        <a
          v-if="panel.hasAdvancedFilters"
          :class="`advanced-filter-button ${compiledAdvancedRules && compiledAdvancedRules.length ? 'applied': ''}`"
          @click="showAdvancedFilters = !showAdvancedFilters"
        >
          <span class="applied-label">
            {{ i18n.filter_applied }} &nbsp;
          </span>
          <i class="mdi mdi-filter-menu" />
        </a>
      </span>
      <div v-if="isCode" class="mongo-info">
        <span>
          {{ i18n.mongo_info }}
          <a href="https://docs.mongodb.com/manual/tutorial/query-documents/" target="_blank">{{ i18n.more_info_here }}</a>
        </span>
      </div>
    </div>
    <AdvancedFilters
      :class="`advanced-filters-container ${showAdvancedFilters ? 'shown': ''}`"
      :schema="schema"
      @search="search"
      @clear="$emit('advancedSearch', $event)"
      @applyFilter="$emit('advancedSearch', $event)"
    />
  </div>
</template>
<script>
import i18n from 'i18n/components/SearchBar.json';
import AdvancedFilters from '@/components/AdvancedFilters';

export default {
  name: 'SearchBar',
  components: {
    AdvancedFilters,
  },
  props: {
    panel: {
      type: Object,
      required: true,
    },
    schema: {
      type: Object,
      default: undefined,
    },
    compiledAdvancedRules: {
      type: Array,
      default: undefined,
    },
    value: {
      type: String,
      default: '',
    }
  },
  data() {
    let autoSearch = localStorage.getItem('setting_autosearch');
    if (autoSearch) {
      try {
        autoSearch = JSON.parse(autoSearch);
      } catch (e) {
        autoSearch = true;
      }
    } else {
      autoSearch = true;
    }
    return {
      hover: false,
      i18n,
      inputFocused: false,
      showAdvancedFilters: false,
      autoSearch,
    };
  },
  computed: {
    isCode() {
      if (this.value && this.value.startsWith('{') || this.value.startsWith('[')) {
        return true;
      }
      return false;
    },
  },
  methods: {
    checkSearchShortcuts(event) {
      if (this.config.keyBindings && this.config.keyBindings.focusSearch) {
        if (event.key === this.config.keyBindings.focusSearch) {
          event.preventDefault();
          const nextInput = document.querySelector('.mainSearch input').select();
          if (nextInput) {
            nextInput.select();
          }
        }
      }
    },

    toggleAutoSearch() {
      this.autoSearch = !this.autoSearch;
      localStorage.setItem('setting_autosearch', JSON.stringify(this.autoSearch));
    },
    search(searchTerm) {
      this.$emit('input', JSON.stringify(searchTerm));
      // this.$emit('advancedSearch', searchTerm);
    },
    inputFocus() {
      this.inputFocused = true;
    },
    inputBlur() {
      this.inputFocused = false;
    },
  },
};
</script>
<style scoped>
.search-bar-container {
  background: #ffffffC0;
  /*box-shadow: 0 2px 3px #ffffffC0, 0 0 0 1px #ffffffC0;*/
  width: 100%;
}

.panelTitleBarSearch {
  flex-grow: 1;
  margin-bottom: 0;
}

.panelTitleBarSearch >>> input {
  border-radius: 0;
  box-shadow: 0 2px 3px rgba(10, 10, 10, 0.1), 0 0 0 1px rgba(10, 10, 10, 0.1);
  border: 0;
  margin-bottom: 0;
}

.search-settings-button {
  position: absolute;
  right: 24px;
  padding: 7px;
  padding-top: 8px;
  margin-top: 3px;
  padding-bottom: 3px;
  border-radius: 64px;
  z-index: 10;
}
.search-settings-button .trigger {
  opacity: 0;
}
.search-settings-button .trigger.visible {
  opacity: 1;
}
.advanced-filter-button {
  background: white;
  position: absolute;
  right: 4px;
  padding: 7px;
  padding-top: 8px;
  margin-top: 3px;
  padding-bottom: 3px;
  border-radius: 64px;
  z-index: 10;
}

.is-code .advanced-filter-button {
  background: #2c292d;
}

.advanced-filter-button.applied {
  background: var(--primary-color);
  color: white;
}
.advanced-filter-button .applied-label {
  display: none;
}
.advanced-filter-button.applied .applied-label {
  display: inline-block;
}
.advanced-filters-container {
  height: 0;
  transition: height 0.2s, padding 0.2s;
  box-shadow: 0 2px 3px rgba(10, 10, 10, 0.3), 0 0 0 1px rgba(10, 10, 10, 0.1);
  padding-top: 0;
  padding-bottom: 0;
  overflow: auto;
}
 .desktop .search-bar-container, .desktop .advanced-filters-container {
}

.advanced-filters-container.shown {
  height: 280px;
  padding: 20px;
}
.is-code >>> input {
  background: #2c292d;
  color: white;
}
.mongo-info {
  background: #2c292d;
  font-size: 80%;
  height: 40px;
  color: #8c9a92;
  transition-duration: 0.2s;
  transition-delay: 0.1s;
  height: 0;
  overflow: hidden;
}
.mongo-info > span {
  display: inline-block;
  padding: 11px;
  padding-left: 60px;
}
.input-focused .mongo-info {
  height: 40px;
}
.mongo-info a:hover {
  color: white;
}
</style>
<style>
.element-list-panel.full-width .search-bar-container, .element-list-panel.full-width .advanced-filters-container {
  max-width: unset;
}
</style>
