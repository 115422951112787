<template>
  <span>
    <span v-if="PermissionManager.can(actions.can_edit_panels) && !editLocked">
      <DataDropdownEditor :panel="panel"/>
    </span>
    <span v-if="PermissionManager.can(actions.can_edit_panels) && !editLocked">
      <WidgetDropdownEditor :panel="panel" />
    </span>
    <span v-if="PermissionManager.can(actions.can_edit_panels) && !editLocked">
      <b-dropdown class="view-toolbar-item" aria-role="list" position="is-bottom-right" multiple>
        <a class="dropdown-trigger top-view-button" slot="trigger">
          <i class="mdi mdi-eye"/>
          Accès
          <i class="mdi mdi-menu-down" />
        </a>
        <AccessDropdownEditor />
      </b-dropdown>
      <TemplateEditorModal :value="panel" @input="$emit('input', $event)"/>
      <b-tooltip label="Tous les paramètres de la vue" position="is-bottom">
        <a class="edit-panel-button panel-title mini-button" @click="$store.dispatch('panels/setEditingView', editingView ? undefined : panel);">
          <i class="mdi mdi-cog" />
        </a>
      </b-tooltip>
    </span>

  </span>
</template>
<script>
import { mapState } from 'vuex';

import DataDropdownEditor from '@/components/DataDropdownEditor';
import WidgetDropdownEditor from '@/components/WidgetDropdownEditor';
import AccessDropdownEditor from '@/components/AccessDropdownEditor';
import TemplateEditorModal from '@/components/modals/TemplateEditorModal';

import PermissionManager from '@/permissions/PermissionManager';
import actions from '@/permissions/actions';

export default {
  props: {
    panel: {
      type: Object,
      required: true,
    },
  },
  components: {
    DataDropdownEditor,
    WidgetDropdownEditor,
    AccessDropdownEditor,
    TemplateEditorModal,
  },
  data () {
    return {
      PermissionManager,
      actions,
    }
  },
  computed: {
    ...mapState({
      editingView: (state) => state.panels.editingView,
    }),
    editLocked: {
      get() {
        return this.$store.state.currentPanel.editLocked;
      },
      set(v) {
        this.$store.commit('currentPanel/setEditLocked', v);
      }
    },
  }
};
</script>
