<template>
  <span @click="$emit('edit', { model: colSchema.model, rowIndex: rowIndex })" @test="$emit('filter', { model: colSchema.model, value: row[colSchema.model] })">
    <span v-if="typeof row[colSchema.model] === 'object' && row[colSchema.model] && row[colSchema.model].length === undefined" class="searchable-field">
      <span class="tag" v-if="colSchema.labelField">
        {{ row[colSchema.model][colSchema.labelField] }}
      </span>
      <b-tooltip v-else>
        <i class="mdi mdi-code-braces-box mdi-24px" style="margin-top: -6px; margin-bottom: -6px" />
        <template slot="content">
          <pre>{{row[colSchema.model]}}</pre>
        </template>
      </b-tooltip>
    </span>
    <span v-else-if="colSchema.type === 'files' && row[colSchema.model]" class="searchable-field">
      <a v-for="f in row[colSchema.model]" :key="f._id" style="display: block;" @click="openFile(f)">
        <i class="mdi mdi-file" />&nbsp;{{f.originalname}}
      </a>
    </span>
    <span v-else-if="typeof row[colSchema.model] === 'object'" class="searchable-field">
      <span v-if="colSchema.type === 'checklist' || colSchema.type === 'customChecklist'">
        <span class="tag" v-for="i in row[colSchema.model]" :key="i">
          {{i}}
        </span>
      </span>
      <b-tooltip v-else-if="row[colSchema.model] !== null">
        <i class="mdi mdi-code-array mdi-24px" style="margin-top: -6px; margin-bottom: -6px" />
        <template slot="content">
          <pre>{{row[colSchema.model]}}</pre>
        </template>
      </b-tooltip>
    </span>
    <TruncatedText v-else-if="typeof row[colSchema.model] === 'string' && row[colSchema.model].length > 200" :value="row[colSchema.model]" />
    <span v-else-if="colSchema.type === 'date' && row[colSchema.model]" class="searchable-field">
      {{ new Date(row[colSchema.model]).toLocaleString('fr', { timeZone: 'UTC' }) }}
    </span>
    <i class="mdi mdi-checkbox-blank-circle-outline mdi-18px searchable-field" v-else-if="row[colSchema.model] === false"/>
    <i class="mdi mdi-checkbox-marked-circle-outline mdi-18px searchable-field" v-else-if="row[colSchema.model] === true"/>
    <span v-else class="searchable-field">
      {{ row[colSchema.model] }}
    </span>
    &nbsp;
  </span>
</template>
<script>
import axios from 'axios';

export default {
  props: {
    row: {
      type: Object,
      required: true
    },
    rowIndex: {
      type: Number,
      required: true
    },
    colSchema: {
      type: Object,
      required: true,
    }
  },
  methods: {
    openFile (file) {
      const baseUrl = process.env.VUE_APP_BASE_URL || 'http://localhost:3332';
      axios({
        url: `${baseUrl}/system_files/${file._id}/file`,
        method: 'GET',
        responseType: 'arraybuffer',
        headers: {
          Authorization: `Token ${this.$store.state.login.user.token}`,
        },
      }).then((response) => {
        if (response.data && response.data.success === false) {
          this.fileDownloadError();
        }
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', file.originalname);
        document.body.appendChild(link);
        link.click();
        link.remove();
      }).catch((e) => {
        this.fileDownloadError();
      });
    },
  }
};
</script>
