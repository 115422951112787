<template>
  <Modal :class="`batch-edit-modal ${isMobile ? 'mobile': 'desktop'}`"
    :active="batchEditModalOpened" :width="1600" @close="callClose"
  >
    <template slot="header">
      <span class="modal-title">
        Edition en masse
      </span>
    </template>
    <portal-target name="portalComplexField" @change="isPortalActive = $event" />
    <b-tabs v-show="!isPortalActive">
      <b-tab-item label="Edition">
        <div class="columns">
          <div class="column">
            <h3 class="title">
              Modifications
            </h3>
            <div v-for="(c, model) in changes" :key="model" class="change">
              <vue-form-generator
                :schema="{ fields: [schema.fields.filter(f => f.model === model)[0]] }"
                :model="editedModel|| {}"
                class="form"
                tag="div"
              />
              <a class="button is-danger" @click="deleteChange(model)">
                <i class="mdi mdi-delete" />
              </a>
            </div>
            <b-message v-if="!changes || Object.keys(changes).length === 0" type="is-warning" has-icon>
              Veuillez séléctionner une propriété à éditer en bas de la fenêtre
            </b-message>
          </div>
          <div class="column">
            <h3 class="title">
              Aperçu des changements
            </h3>
            <pre>{{JSON.stringify(editedModel, 0, 2)}}</pre>
          </div>
        </div>
      </b-tab-item>
      <b-tab-item label="Elements sélectionnés">
        {{selection.length}}
      </b-tab-item>
    </b-tabs>
    <template slot="footer">
      <b-dropdown v-if="schema && schema.fields" append-to-body position="is-top-left">
        <a slot="trigger" class="button">
          Sélectionnez une propriété
          <i class="mdi mdi-menu-up" />
        </a>
        <b-dropdown-item
          v-for="f in schema.fields"
          :key="f.model"
          :disabled="isPropertyDisabled(f.model)"
          @click="addChange(f.model)"
        >
          {{f.label}}
        </b-dropdown-item>
      </b-dropdown>
      <a class="button is-primary" @click="apply">
        <i class="mdi mdi-save" />
        Appliquer les modifications
      </a>
    </template>
  </Modal>
</template>
<script>
import { mapState } from 'vuex';
import { isMobile } from 'mobile-device-detect';
import Modal from '@/components/modals/Modal';
import Api from '@/core/Api';
import deepClone from '@/core/utils/deepClone';

export default {
  name: 'BatchEditModal',
  components: {
    Modal,
  },
  props: {
    panel: {
      type: Object,
      default: undefined,
    },
    schema: {
      type: Object,
      default: undefined,
    },
  },
  computed: {
    ...mapState({
      batchEditModalOpened: (state) => state.config.batchEditModalOpened,
      selection: (state) => state.config.selection,
    }),
  },
  data() {
    return {
      isMobile,
      changes: {},
      editedModel: {},
      isPortalActive: false,
    };
  },
  watch: {
    batchEditModalOpened() {
      this.isPortalActive = false;
    },
  },
  methods: {
    async apply() {
      // TODO reload records at the moment of save
      console.log('apply', this.selection, this.panel.collection, this.editedModel);
      const selection = deepClone(this.selection);

      for (const r of selection) {
        for (const k in this.editedModel) {
          r[k] = this.editedModel[k];
        }
      }
      console.log('selection edited', selection);
      const resUpdated = await this.$store.dispatch(`${this.panel.storeModule || 'abstractElements'}/saveObject`, {
        collection: this.panel.collection,
        destinationBucket: this.panel.bucket,
        objects: selection,
        sort: this.panel.sort,
        schema: this.schema,
      });
      console.log('resUpdated', resUpdated);
      this.callClose();
      // TODO handle loading
      // TODO handle updated res and errors
      /* let ids = [];
      for (const r of this.selection) {
        ids.push(r._id);
      }
      const res = await Api.get(`/${this.panel.collection}?query={_id: "${this.selection[0]._id}"}`);
      console.log('res', res);
       */
    },
    callClose() {
      this.$store.commit('config/setBatchEditModalOpened', false);
    },
    addChange(model) {
      this.changes = {
        ...this.changes,
        [model]: {},
      };
    },
    deleteChange(model) {
      const changes = { ...this.changes };
      delete changes[model];
      this.changes = changes;
      delete this.editedModel[model];
    },
    isPropertyDisabled(model) {
      return this.changes[model] !== undefined;
    },
  },
};
</script>
<style scoped>
.batch-edit-modal >>> .modal-card-body {
  padding: 0;
  height: 85vh;
}
.change {
  display: flex;
}
.change >>> .form {
  flex-grow: 1;
  padding-right: 20px;
}
</style>
