<template>
  <b-dropdown class="document-dropdown" aria-role="list" @change="changeValue">
    <button slot="trigger" class="button is-info">
      <i v-if="icon" :class="`dropdown-icon mdi mdi-${icon}`" />
      <span v-if="value">
        {{ value[labelField] }}
      </span>
      <span v-else>
        {{ selectOptionLabel || i18n.select_an_option }}
      </span>
      <i class="mdi mdi-menu-down" />
    </button>

    <b-dropdown-item v-if="includeEmptyChoice" aria-role="listitem" @click="changeValue()">
      {{ i18n.everything }}
    </b-dropdown-item>
    <b-dropdown-item v-for="d in documents" :key="d._id" :value="d" aria-role="listitem">
      {{ d[labelField] }}
    </b-dropdown-item>
  </b-dropdown>
</template>
<script>
import Api from '@/core/Api';
import i18n from 'i18n/components/DocumentDropdown.json';

export default {
  name: 'DocumentDropdown',
  props: {
    collection: {
      type: String,
      required: true,
    },
    labelField: {
      type: String,
      required: true,
    },
    value: {
      type: Object,
      default: undefined,
    },
    icon: {
      type: String,
      default: undefined,
    },
    selectOptionLabel: {
      type: String,
      default: undefined,
    },
    includeEmptyChoice: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      i18n,
      documents: [],
      selectedDocument: undefined,
    };
  },
  watch: {
    collection: {
      async handler(c) {
        if (c) {
          const documentsPayload = await Api.get(`/${c}`);
          if (documentsPayload.data && documentsPayload.data.documents) {
            this.documents = documentsPayload.data.documents;
          }
        }
      },
      immediate: true,
    },
  },
  methods: {
    changeValue(e) {
      this.$emit('input', e);
    },
  },
};
</script>
<i18n>
{
  "en": {
    "select_an_option": "Select an option",
    "everything": "Everything"
  },
  "fr": {
    "select_an_option": "Selectionnez une option",
    "everything": "Tout"
  }
}
</i18n>
<style scoped>
.document-dropdown >>> .dropdown-content {
  max-height: 300px;
  overflow-y: auto;
}
.dropdown-icon {
  margin-right: 10px;
}
</style>
