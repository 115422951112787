<template>
  <div class="edit-modal">
    Tri des éléments
    <vue-form-generator
      :schema="schema"
      :model="computedValue"
      class="form"
      tag="div"
      @model-updated="modelUpdated"
    />

    <a class="button" @click="reset">
      Réinitialiser
    </a>
    <a class="button" @click="apply">
      Appliquer
    </a>
  </div>
</template>
<script>
import deepClone from '@/core/utils/deepClone';

export default {
  props: {
    value: {
      type: Object,
      default: undefined
    },
    schemaName: {
      type: String,
      default: undefined
    },
    defaultSort: {
      type: [Object, String],
      default: undefined
    }
  },
  data () {
    const computedValue = [];
    if (this.value) {
      for (let p of Object.keys(this.value)) {
        computedValue.push({ key: p, direction: this.value[p] });
      }
    }

    return {
      schema: {
        fields: [{
          type: 'array',
          model: 'res',
          showRemoveButton: true,
          items: {
            type: 'object',
            schema: {
              fields: [{
                //type: 'text',
                type: 'schemapropertyselector',
                schemaName: this.schemaName,
                model: 'key',
                styleClasses: [
                  'label-on-top',
                  'no-margin',
                  'half-width',
                ],
              }, {
                type: 'select',
                model: 'direction',
                selectOptions: {
                  hideNoneSelectedText: true,
                },
                styleClasses: [
                  'label-on-top',
                  'no-margin',
                  'half-width',
                ],
                values: [
                  { id: 1, name: 'Croissant' },
                  { id: -1, name: 'Décroissant' },
                ],
              }]
            }
          }
        }],
      },
      computedValue: { res: computedValue }
    };
  },
  watch: {
    value (v) {
      const computedValue = [];
      if (this.value) {
        for (let p of Object.keys(this.value)) {
          computedValue.push({ key: p, direction: this.value[p] });
        }
        this.computedValue = { res: computedValue };
      }

    }
  },
  methods: {
    modelUpdated() {
      this.computedValue.res = this.computedValue.res.map(i => {
        if(i === null || i === undefined) {
          return { direction: 1 };
        } else if(i.direction === undefined) {
          i.direction = 1;
        }
        return i;
      });
      this.computedValue = deepClone(this.computedValue);
    },
    apply() {
      const res = {};
      for (let f of this.computedValue.res) {
        res[f.key] = f.direction;
      }
      this.$store.commit('currentPanel/setCurrentPanelSort', res);
    },
    reset() {
      this.$store.commit('currentPanel/setCurrentPanelSort', this.defaultSort);
    }
  }
};
</script>
<style scoped>
.edit-modal {
  width: 400px;
  padding: 20px
}

.edit-modal >>> .field-array {
  display: block!important;
}
.edit-modal >>> .field-array > .field-wrap > div > .wrap > div > input {
  margin: 0;
  border-radius: 0 4px 4px 0;
}
.edit-modal >>> .autocomplete input {
  border-radius: 4px 0 0 4px;
}
.edit-modal >>> select {
  height: 40px;
  border-radius: 0;
}

</style>
