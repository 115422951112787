<template>
  <div class="card" style="margin-bottom: 0!important;">
    <QueryBuilder
      v-model="qbres"
      :schema="schema"
      :rules="rules"
      @search="$emit('search', toMongoQuery(qbres))"
      @clear="clear"
      @applyFilter="applyFilter"
    />
  </div>
</template>
<script type="text/javascript">
import QueryBuilder from '@/components/ui/QueryBuilder';

const transformQueryClause = (item) => {
  if (['$and', '$or'].includes(item.operator)) {
    item = {
      [item.operator]: item.value,
    };
  } else {
    item = {
      [item.fieldName]: {
        [item.operator]: item.value,
      },
    };
  }
  return item;
};
export default {
  components: {
    QueryBuilder,
  },
  props: {
    schema: {
      type: Object,
      default: undefined,
    },
  },
  data() {
    return {
      rules: [],
      qbres: undefined,
    };
  },
  methods: {
    clear() {
      this.rules = [];
      this.$emit('clear');
    },
    applyFilter(f) {
      this.rules = JSON.parse(JSON.stringify(f));
      this.$emit('applyFilter', this.toMongoQuery(f));
    },
    toMongoQuery(qbres) {
      if (!qbres) {
        return;
      }
      function mapEachObject(obj, fn) {
        for (const key in obj) {
          const value = obj[key];
          if (value !== undefined) {
            if (Array.isArray(value)) {
              for (let i = 0; i < value.length; i++) {
                mapEachObject(value[i], fn);
                value[i] = fn(value[i]);
              }
            } else if (value && typeof value === 'object') {
              mapEachObject(value, fn);
              obj[key] = fn(value);
            }
          }
        }
      }

      // FIXME add a deepclone utility in ~/core
      const res = JSON.parse(JSON.stringify(qbres));

      mapEachObject(res, transformQueryClause);
      return res;
    },
  },
};
</script>
<style type="text/css" scoped>
.card {
  margin-bottom: 10px;
  padding: 0px !important;
  padding-top: 28px;
  background: #F6F6F6;
  box-shadow: 0;
}
</style>
