<template>
  <div>
    <QueryBuilderGroup
      operator="$and"
      class="root-element"
      :root-element="true"
      :operators="operators"
      :schema="schema"
      :rules="rules"
      @applyFilter="$emit('applyFilter', $event)"
    />
    <span class="field filter-actions">
      <p class="control">
        <button
          class="button is-small is-danger"
          :disabled="!rules || rules.length === 0"
          @click="$emit('clear')"
        >
          <i class="mdi mdi-close" />
          {{ i18n.clear }}
        </button>
      </p>
      <p class="control">
        <button class="button is-small is-primary" @click="$emit('search')">
          <i class="mdi mdi-magnify" />
          {{ i18n.search }}
        </button>
      </p>
    </span>
  </div>
</template>
<script>
import QueryBuilderGroup from '@/components/ui/QueryBuilder/QueryBuilderGroup';
import i18n from 'i18n/components/ui/QueryBuilder.json';

export default {
  name: 'QueryBuilder',
  components: {
    QueryBuilderGroup,
  },
  props: {
    schema: {
      type: Object,
      default: undefined,
    },
    rules: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {
      i18n,
      operators: [{
        label: i18n.equal,
        value: '$eq',
      }, {
        label: i18n.different,
        value: '$ne',
      }, {
        label: i18n.smaller_than,
        value: '$lt',
      }, {
        label: i18n.greater_than,
        value: '$gt',
      }, {
        label: i18n.smaller_than_or_equal,
        value: '$lte',
      }, {
        label: i18n.greater_than_or_equal,
        value: '$gte',
      }, {
        label: i18n.regex,
        value: '$regex',
      }],
    };
  },
  watch: {
    rules: {
      handler(v) {
        this.$emit('input', v);
      },
      deep: true,
    },
  },
};
</script>
<style type="text/css" scoped>
.rule {
  margin-bottom: 0;
}
.rule >>> input {
  width: 200px;
}
.rule >>> select {
  width: 100px;
}
.root-element {
  margin-top: 0;
  border: none;
}
.filter-actions {
  margin-right: 24px;
  float: right;
  display: flex;
}
</style>
